import React from 'react';
import {CustomNavbar} from "../index";
import './style.css';
import {Link} from "react-router-dom";

const LandingBannerComponent = ({redirectionPage,HomeLandingText, RideshareText, BannerImage, BannerTextPara, image_banner_class, firstCardRef,handleScroll}) => {
    return(
        <div className='landing_banner_main_wrapper'>

            <div className='landing_banner_inner_wrapper'>
                <CustomNavbar scrollValue={30} />

               <div className='content_landing_wrapper_main'>
                   <div className="content_landing_wrapper">
                       {HomeLandingText}

                       <p>
                           {BannerTextPara}
                       </p>
                       
                       
                       <div className='btn_landing_wrapper'>
                       {firstCardRef?  <button  className='landing_banner_button' onClick={handleScroll}>
                                   Get Started
                               </button> :
                           <Link to={`${redirectionPage ? redirectionPage : '/getStarted'}`}>
                          
                               <a href="" className='landing_banner_button'>
                                   Get Started
                               </a>

                           </Link>
                           }

                       </div>

                   </div>
                   <div className={`content_image_wrapper ${image_banner_class ? 'image_banner_class' : null}`}>
                       <img src={BannerImage} alt=""/>
                   </div>

               </div>

            </div>


        </div>
    )

}

export default LandingBannerComponent