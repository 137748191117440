import React from 'react'
import './style.css'
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import {
    AboutChexComponent, ChexSolutionComponent,
    CommunityBannerComponent, CustomFooter,
    HelpBusinessSectionComponent,
    HomeBadgeBoxComponent, KeyBenefitsComponent, WhyChexAiComponent
} from "../../Components";
import {Link} from "react-router-dom";

const MicromobilityScreen = () =>{
    return(
        <div>
            <LandingBannerComponent
                HomeLandingText={<div> <h3> <span className='darkGray'>Municipality </span> parking compliance  <span className='darkGray'> solution For The </span> <span className='darkBlue'> microMobility Industry </span></h3></div>}
                BannerImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470844/Group_508_2_jtdghv_wfn4uc.png'}
                image_banner_class={"image_banner_class"}
                redirectionPage={'/contactPage'}
            />

            <div className='box_badge_wrapper_outer'>
                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_3_ktpims_ynuhl9.png"}
                    badgeHeading={"Easy & Convenient"}
                    badgeInfo={"Clear process to meet local, state and federal regulatory requirements"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_4_jp8igk_ezxbdv.png"}
                    badgeHeading={"Advanced & Efficient"}
                    badgeInfo={"Our system uses machine learning technology which gives you results instantly"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_5_bccfay_lefazy.png"}
                    badgeHeading={"Cost Effective"}
                    badgeInfo={"We provide the best service at a lower cost when compared to competition"}

                />

            </div>

            <div>
                <ChexSolutionComponent
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470845/Group_509_ruzaom_ew64er.png'}
                />
            </div>

            <div>
                <WhyChexAiComponent
                    firstIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470825/Frame_2_y1qraz_dkzwqf.png'}
                    firstPara={'API and SDK provided and integrated within our Partner app. SDK allows riders to follow guidelines before taking a photo'}
                    secondIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470827/Frame_3_ehxmhr_mq8kge.png'}
                    secondPara={'Customizable to satisfy changing municipality requirements'}
                    thirdIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470826/Frame_4_mrq727_tn58xy.png'}
                    thirdPara={'Riders and our partners get instant notification of parking discrepancy and visibility'}

                />
            </div>

            <div>
                <KeyBenefitsComponent />
            </div>


            <div className='section_pricing_micro_mobility'>

                <div className='inner_section_pricing_mobility'>
                    <h3>
                        Pricing
                    </h3>
                    <p>
                        Our pricing can vary based on the service needed.
                        <br />
                        Please contact us to discuss your requirements and pricing.
                    </p>

                    {/*<a href="" className='btn_pricing_mobility'>*/}
                        <Link to='/contactPage' className='btn_pricing_mobility'>
                            Contact Us For Pricing
                        </Link>

                    {/*</a>*/}

                </div>

            </div>




            <div>
                <AboutChexComponent />
            </div>
            <div>
                <CommunityBannerComponent />
            </div>

            <div>
                <HelpBusinessSectionComponent
                    headingSolution={<h2>How companies can benefit with Chex.AI</h2>}
                    paraSolution={'Chex.AI is an all-in-one solution customized for micromobility industry. Companies can incorporate Chex.AI’s SDK and our Machine Learning technology and human interface can identify and notify instantly of any parking non-compliance. Our system is customizable to meet market requirements keeping city walkways and roads clean, safe and ADA compliant.'}
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470849/Group_511_xjhby8_hbklsc.png'}
                    wrapper_reverse_order={'wrapper_reverse_order'}
                />
            </div>

            <div>
                <HelpBusinessSectionComponent
                    headingSolution={<h2>How City Governments can benefit partnering with Chex.AI</h2>}
                    paraSolution={'City Governments can keep the city walkways and roads safe, clean and ADA compliant by partnering with Chex.AI. Our Machine Learning technology and human interface can identify and notify instantly of any parking non-compliance. Our technology infrastructure also enables us to incorporate data analytics that municipalities can utilize to meet their smart city goals. The parking, traffic and location based data analytic will provide insight on smart space management helping cities allocate appropriate funds to meet needs for the changing landscape.'}
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470841/Group_510_mntmoh_njycqj.png'}
                    imageGovtStyle={'imageGovtStyle'}
                />
            </div>

            <div>
                <HelpBusinessSectionComponent
                    headingSolution={<h2>How can we help your<span className='business_help_span_style'> business?</span></h2>}
                    paraSolution={'We can help your business optimize asset inspection process. Our interactive dashboard allows you to review data in real time and provide feedback to your customers instantaneously!'}
                    buttonText={'Contact Us'}
                    wrapper_background_style={'wrapper_background_style'}
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470847/Group_448_nkwgnj_uiddnk.png'}

                />
            </div>

            <div>
                <CustomFooter />
            </div>

        </div>
    )
}
export default MicromobilityScreen