/* eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useToasts } from "react-toast-notifications";

import ActionCreators from "../../actions";

import { SelectCompanyScreen } from "../../Screens";
import { BiWindows } from "react-icons/bi";
import {
  getInspectionCompaniesEdit,
  getInspectionDetail,
} from "../../actions/vehicleInspection";
import axios from "axios";
import { Api } from "../../services/configs";
import { useHistory } from "react-router-dom";

const SelectCompanyContainer = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  };

  const {
    createInspection,
    createReInspection,
    getCompaniesByState,
    stateCompanies,
    companies,
    match,
    getInspectionCompaniesEdit,
    getInspectionDetail,
    getNewCompanyData,
  } = props;

  const [inspectionChecked, setInspectionChecked] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingcompny, setLoadingcompny] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [inspectionState, setInspectionState] = useState("");
  const [stateEmpty, setStateEmpty] = useState(false);
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [alreadyExistModal, setAlreadyExistModal] = useState(false);
  const [inspectionErrorResp, setInspectionErrorResp] = useState("");
  const [getInspectionDetailData, setGetInspectionDetailData] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [showTuroModal, setShowTuroModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [turoState, setTuroState] = useState("");

  useEffect(() => {
    if (
      match?.params?.inspection === "true" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };
      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "edit" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };
      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "addCompany" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
      const body = {
        stateName: props?.location?.state?.state,
      };

      getCompaniesByState(
        body,
        setLoadingcompny,
        match?.params?.inspection,
        props?.location?.state?.selectedCompanies
      );
    }

    if (inspectionState != "") {
      const body = {
        stateName: inspectionState,
      };

      getCompaniesByState(body, setLoadingcompny);
    }

    if (
      match?.params?.inspection === "addCompany" &&
      inspectionState === "" &&
      props?.location?.state?.state
    ) {
    } else {
      let myArray = [];
      props?.location?.state?.selectedCompanies?.map((data) => {
        myArray.push(data);
      });
      setInspectionChecked(myArray);
    }
  }, [inspectionState]);

  const editTuruYear = () => {
    setShowErrorMessage(false);
  };

  const closeTuroModal = () => {
    var arr = inspectionChecked;
    arr = arr.filter((obj) => obj !== 15);
    setInspectionChecked(arr);
    setShowTuroModal(false);
  };

  const handleYearSubmit = (values) => {
    const { year } = values;
    if (year <= 2018) {
      setShowErrorMessage(true);
    } else {
      setShowTuroModal(false);
    }
  };

  const handleInspectionCheck = (id) => {
    if (id === 15 && !inspectionChecked.includes(15)) {
      setShowTuroModal(true);
    }
    const arr = [...inspectionChecked];
    let index = arr.indexOf(id);
    if (index > -1) {
      if (id === 16) {
        handleTuro("Turoadd");
      } else if (id === 15) {
        handleTuro("hopskipAdd");
      }
      arr.splice(index, 1);
      setInspectionChecked([...arr]);
    } else {
      if (id === 16) {
        handleTuro("Turoremove");
      } else if (id === 15) {
        handleTuro("hopskipRemove");
      }
      arr.push(id);
      setInspectionChecked([...arr]);
    }
  };

  const handleTuro = (checkType) => {
    if (
      checkType === "Turoremove" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      setTuroState(stateCompanies.splice(4, 1));
    } else if (
      checkType === "Turoadd" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      if (turoState.length !== 0) {
        stateCompanies.splice(4, 0, turoState[0]);
      }
    } else if (
      checkType === "hopskipAdd" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      if (turoState.length !== 0) {
        stateCompanies.splice(5, 0, turoState[0]);
      }
    } else if (
      checkType === "hopskipRemove" &&
      (inspectionState === "California" ||
        inspectionState === "Northern California (Bay Area)" ||
        inspectionState === "Northern California (Sacramento Region)" ||
        inspectionState === "Northern California (Other)" ||
        inspectionState === "Central California" ||
        inspectionState === "Southern California (LA region)" ||
        inspectionState === "Southern California (Orange County)" ||
        inspectionState === "Southern California (San Diego Region)" ||
        inspectionState === "Southern California (Other)" ||
        props?.location?.state?.state === "California")
    ) {
      setTuroState(stateCompanies.splice(5, 1));
    }
  };

  if (match?.params?.inspection === "edit") {
    useEffect(() => {
      const { match } = props;

      // axios
      //     .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/detail/${props?.location?.state?.InspectionId}`, { headers })
      //     .then((resp) => {
      //
      //           setGetInspectionDetailData(resp.data.Vehicle.licensePlateNumber);
      //
      //         }
      //     )
      //     .catch((err) => {
      //
      //     });
      getInspectionDetail(
        props?.location?.state?.InspectionId,
        setGetInspectionDetailData,
        setDetailLoading,
        setLicensePlateNumber
      );
    }, []);
  } else if (match?.params?.inspection === "addCompany") {
    useEffect(() => {
      const { match } = props;
      getInspectionDetail(
        props?.location?.state?.InspectionId,
        setGetInspectionDetailData,
        setDetailLoading,
        setLicensePlateNumber
      );
    }, []);
  }

  // const inspectionDataEdit = {
  //
  //   plateNumber:getInspectionDetailData?.Vehicle?.licensePlateNumber,
  //
  // }
  const handleSubmitAlreadyExist = () => {
    window.location.href = `/vehicleinspection/${inspectionErrorResp?.inspectionId}/${inspectionErrorResp?.vehicleId}?lyftUser=${inspectionErrorResp?.lyftInspection}`;
  };

  const handleAutoReInspection = (e) => {
    setAlreadyExistModal(false);
    setLicensePlateNumber("");
    setNumberEmpty(true);
  };

  const handleLicensePlateNumber = (number) => {
    setLicensePlateNumber(number);
  };

  const handleState = (state) => {
    setInspectionState(state);
  };

  const handlEmptyFields = () => {
    inspectionChecked.length === 0 ? setCheckEmpty(true) : setCheckEmpty(false);
  };

  const handleCreateInspection = (reInspection) => {
    if (reInspection === "false") {
      const body = {
        licensePlateNumber,
        inspectionState,
        companies: inspectionChecked,
      };
      createInspection(
        body,
        setLoadingSelect,
        addToast,
        setAlreadyExistModal,
        setInspectionErrorResp,
        setLicensePlateNumber,
        setNumberEmpty
      );
    } else if (reInspection === "true") {
      const body = {
        inspectionState: inspectionState
          ? inspectionState
          : props?.location?.state?.state,
        companies: inspectionChecked,
      };
      createReInspection(
        props?.location?.state?.vehicleId,
        body,
        setLoadingSelect
      );
    }
  };

  const handleEditInspection = (reInspection) => {
    if (reInspection === "edit") {
      let companiesToBeSend = inspectionChecked?.filter(
        (item) => item !== null
      );
      const body = {
        companies: companiesToBeSend,
        licensePlateNumber: licensePlateNumber,
      };

      const vehicleId = props?.location?.state?.VehicleId;

      // const { match } = props;

      // changeVehicleStatus(match?.params?.id, match?.params?.vehicleId, setVehicleStatusLoading, history);
      // setLoadingSelect(true);
      // axios
      //     .post(`${Api}/update/inspection/${props?.location?.state?.InspectionId}`, body, { headers })
      //     .then((resp) => {
      //       // dispatch(setInspectionByStatus(resp.data));
      //       setLoadingSelect(false);
      //       history.push(`/checkoutScreen/${props?.location?.state?.InspectionId}/${props?.location?.state?.VehicleId}`);
      //
      //
      //     })
      //     .catch((err) => {
      //       setLoadingSelect(false);
      //     });

      getInspectionCompaniesEdit(
        props?.location?.state?.InspectionId,
        body,
        setLoadingSelect,
        vehicleId,
        history,
        addToast
      );
    }
  };

  const handleAddNewCompany = (reInspectionData) => {
    if (reInspectionData === "addCompany") {
      const body = {
        companies: inspectionChecked,
      };

      const inspectionId = props?.location?.state?.InspectionId;
      const vehicleId = props?.location?.state?.vehicleId;

      getNewCompanyData(
        inspectionId,
        body,
        setLoadingSelect,
        history,
        vehicleId,
        addToast
      );
    }

    //
    // let newArray = [];
    // stateCompanies?.map((company)=>
    //     props?.location?.state?.selectedCompanies.map((data)=>
    //
    //     )
    //
    // )
  };

  useEffect(() => {
    if (
      (props?.location?.state?.state === "California" ||
        props?.location?.state?.state === "Northern California (Bay Area)" ||
        props?.location?.state?.state ===
          "Northern California (Sacramento Region)" ||
        props?.location?.state?.state === "Northern California (Other)" ||
        props?.location?.state?.state === "Central California" ||
        props?.location?.state?.state === "Southern California (LA region)" ||
        props?.location?.state?.state ===
          "Southern California (Orange County)" ||
        props?.location?.state?.state ===
          "Southern California (San Diego Region)" ||
        props?.location?.state?.state === "Southern California (Other)") &&
      (inspectionChecked?.includes(16) ||
        props?.location?.state?.selectedCompanies?.includes(16))
    ) {
      setTuroState(stateCompanies?.splice(4, 1));
    } else if (
      (props?.location?.state?.state === "California" ||
        props?.location?.state?.state === "Northern California (Bay Area)" ||
        props?.location?.state?.state ===
          "Northern California (Sacramento Region)" ||
        props?.location?.state?.state === "Northern California (Other)" ||
        props?.location?.state?.state === "Central California" ||
        props?.location?.state?.state === "Southern California (LA region)" ||
        props?.location?.state?.state ===
          "Southern California (Orange County)" ||
        props?.location?.state?.state ===
          "Southern California (San Diego Region)" ||
        props?.location?.state?.state === "Southern California (Other)") &&
      (inspectionChecked?.includes(15) ||
        props?.location?.state?.selectedCompanies?.includes(15))
    ) {
      setTuroState(stateCompanies?.splice(5, 1));
    }
  }, [stateCompanies]);

  const filteredCompanies = () => {
    const selectedCompanies = [...props?.location?.state?.selectedCompanies];
    if (selectedCompanies.length > 0) {
      return stateCompanies?.filter(
        (company) => !selectedCompanies.includes(company.id)
      );
    } else {
      return [];
    }
  };

  const filteredCompaniesSelected = () => {
    const selectedCompanies = [...props?.location?.state?.selectedCompanies];
    if (selectedCompanies.length > 0) {
      return stateCompanies?.filter((company) =>
        selectedCompanies.includes(company.id)
      );
    } else {
      return [];
    }
  };

  return (
    <SelectCompanyScreen
      filteredCompanies={filteredCompanies}
      filteredCompaniesSelected={filteredCompaniesSelected}
      companies={companies}
      stateCompanies={stateCompanies}
      checkEmpty={checkEmpty}
      stateEmpty={stateEmpty}
      numberEmpty={numberEmpty}
      handleState={handleState}
      setStateEmpty={setStateEmpty}
      setCheckEmpty={setCheckEmpty}
      submitLoading={submitLoading}
      loadingSelect={loadingSelect}
      loadingcompny={loadingcompny}
      setNumberEmpty={setNumberEmpty}
      inspectionState={inspectionState}
      setSubmitLoading={setSubmitLoading}
      handlEmptyFields={handlEmptyFields}
      inspectionChecked={inspectionChecked}
      alreadyExistModal={alreadyExistModal}
      licensePlateNumber={licensePlateNumber}
      reInspection={match?.params?.inspection}
      inspectionErrorResp={inspectionErrorResp}
      handleInspectionCheck={handleInspectionCheck}
      handleAutoReInspection={handleAutoReInspection}
      handleCreateInspection={handleCreateInspection}
      reInspectionState={props?.location?.state?.state}
      // InspectionEditState={inspectionDataEdit}
      getInspectionDetailData={getInspectionDetailData}
      handleSubmitAlreadyExist={handleSubmitAlreadyExist}
      handleLicensePlateNumber={handleLicensePlateNumber}
      handleEditInspection={handleEditInspection}
      detailLoading={detailLoading}
      reInspectionPlateNumber={props?.location?.state?.plateNumber}
      handleAddNewCompany={handleAddNewCompany}
      selectedCompanies={props?.location?.state?.selectedCompanies}
      setShowTuroModal={setShowTuroModal}
      showTuroModal={showTuroModal}
      handleYearSubmit={handleYearSubmit}
      showErrorMessage={showErrorMessage}
      closeTuroModal={closeTuroModal}
      editTuruYear={editTuruYear}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehiclesByStatus: state.vehicleInstruction.inspectionByStatus,
    companies: state?.auth?.companies?.data,
    stateCompanies: state?.auth?.stateCompanies?.data,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCompanyContainer);
