/* eslint-disable */
import React from "react";
import { ClipLoader } from "react-spinners";
import { Select, Modal, Form, Input } from "antd";
import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import { Header, LogoutModal } from "../../Components";
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentFooterareaInspectionInstruction,
  MainBgInsertDetails,
} from "./style.js";
import "./style.css";

const SelectCompanyScreen = ({
  // companies,
  stateEmpty,
  checkEmpty,
  handleState,
  numberEmpty,
  setStateEmpty,
  loadingcompny,
  submitLoading,
  stateCompanies,
  setSubmitLoading,
  inspectionChecked,
  alreadyExistModal,
  inspectionErrorResp,
  setAlreadyExistModal,
  handleInspectionCheck,
  handleCreateInspection,
  handleLicensePlateNumber,
  handleSubmitAlreadyExist,
  reInspectionPlateNumber,
  handleAutoReInspection,
  reInspectionState,
  inspectionState,
  licensePlateNumber,
  loadingSelect,
  setNumberEmpty,
  setCheckEmpty,
  reInspection,
  handleEditInspection,
  getInspectionDetailData,
  detailLoading,
  handleAddNewCompany,
  selectedCompanies,
  filteredCompaniesSelected,
  filteredCompanies,
  showTuroModal,
  setShowTuroModal,
  handleYearSubmit,
  showErrorMessage,
  closeTuroModal,
  editTuruYear,
}) => {
  const { Option } = Select;
  let filterArray = stateCompanies;
  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <div className="select-company-header">
            <div className="select-company-header-content">
              <Header title={true} />
            </div>
          </div>
          <MainDownContainer>
            <ContentFooterareaInspectionInstruction
              className={detailLoading ? "overlayLoader" : ""}
            >
              {detailLoading ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "48%",
                    zIndex: 1,
                  }}
                >
                  <ClipLoader color={"#FF7A00"} />
                </div>
              ) : null}

              {reInspection === "true" ? (
                <div className="companyInstructionHeading">Re-Inspection</div>
              ) : (
                <div className="companyInstructionHeading">New Inspection</div>
              )}
              <div>
                <i>
                  <div className="select-company-inspection-price">
                    <b>$29.99</b> for first inspection and <b>$17.99</b> for
                    each additional selection
                  </div>
                </i>
                {reInspection === "true" ? (
                  <>
                    <div className="select-company-heading">
                      License Plate Number{" "}
                    </div>
                    <div className="license-plate">
                      <input
                        type="text"
                        disabled={true}
                        placeholder={reInspectionPlateNumber}
                        className="license-plate-text"
                      />
                    </div>
                  </>
                ) : reInspection === "edit" ? (
                  <>
                    <div className="select-company-heading">
                      License Plate Number{" "}
                    </div>
                    <div className="license-plate">
                      <input
                        type="text"
                        disabled={false}
                        placeholder="License Plate Number"
                        value={licensePlateNumber}
                        className="license-plate-text"
                        onChange={(number) => {
                          handleLicensePlateNumber(number.target.value);
                          setNumberEmpty(false);
                        }}
                      />
                    </div>
                  </>
                ) : reInspection === "addCompany" ? (
                  <>
                    <div className="select-company-heading">
                      License Plate Number{" "}
                    </div>
                    <div className="license-plate">
                      <input
                        type="text"
                        disabled={true}
                        placeholder="License Plate Number"
                        value={licensePlateNumber}
                        className="license-plate-text"
                        onChange={(number) => {
                          handleLicensePlateNumber(number.target.value);
                          setNumberEmpty(false);
                        }}
                      />
                    </div>
                  </>
                ) : reInspection === "false" ? (
                  <>
                    {numberEmpty === true ? (
                      <div
                        className="select-company-heading"
                        style={{ color: "red" }}
                      >
                        Enter License Plate Number{" "}
                      </div>
                    ) : (
                      <div className="select-company-heading">
                        Enter License Plate Number{" "}
                      </div>
                    )}
                    <div className="license-plate">
                      <input
                        type="text"
                        value={licensePlateNumber}
                        placeholder="License Plate Number"
                        className="license-plate-text"
                        onChange={(number) => {
                          handleLicensePlateNumber(number.target.value);
                          setNumberEmpty(false);
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {reInspection === "true" ? (
                  <>
                    {stateEmpty === true ? (
                      <div
                        className="select-state-heading"
                        style={{ color: "red" }}
                      >
                        Select Inspection State
                      </div>
                    ) : (
                      <div className="select-state-heading">
                        Select Inspection State
                      </div>
                    )}

                    <div className="drop-down-container">
                      <Select
                        className="drop-down"
                        labelInValue
                        // defaultValue={{ value: 'Select State', color:'red'}}
                        placeholder={reInspectionState}
                        onChange={(e) => {
                          handleState(e?.value);
                          setStateEmpty(false);
                        }}
                      >
                        <Option value="Alabama">Alabama</Option>
                        <Option value="Alaska">Alaska</Option>
                        <Option value="Arizona">Arizona</Option>
                        <Option value="Arkansas">Arkansas</Option>
                        <Option value="Bahamas">Bahamas</Option>
                        <Option value="Northern California (Bay Area)">
                          {" "}
                          Northern California (Bay Area)
                        </Option>
                        <Option value="Northern California (Sacramento Region)">
                          {" "}
                          Northern California (Sacramento Region)
                        </Option>
                        <Option value="Northern California (Other)">
                          Northern California (Other)
                        </Option>
                        <Option value="Central California">
                          Central California
                        </Option>
                        <Option value="Southern California (LA region)">
                          Southern California (LA Region)
                        </Option>
                        <Option value="Southern California (Orange County)">
                          {" "}
                          Southern California (Orange County)
                        </Option>
                        <Option value="Southern California (San Diego Region)">
                          {" "}
                          Southern California (San Diego Region)
                        </Option>
                        <Option value="Southern California (Other)">
                          Southern California (Other)
                        </Option>
                        <Option value="Colorado">Colorado</Option>
                        {/*<Option value="Connecticut">Connecticut</Option>*/}
                        {/* <Option value="Delaware">Delaware</Option> */}
                        <Option value="Florida">Florida</Option>
                        <Option value="Georgia">Georgia</Option>
                        {/*<Option value="Hawaii">Hawaii</Option>*/}
                        <Option value="Idaho">Idaho</Option>
                        {/* <Option value="Illinois">Illinois</Option> */}
                        <Option value="Indiana">Indiana</Option>
                        <Option value="Kentucky">Kentucky</Option>
                        <Option value="Kansas">Kansas</Option>
                        <Option value="Iowa">Iowa</Option>
                        {/*<Option value="Louisiana">Louisiana</Option>*/}
                        {/*<Option value="Maine">Maine</Option>*/}

                        <Option value="Michigan">Michigan</Option>
                        <Option value="Minnesota">Minnesota</Option>
                        <Option value="Mississippi">Mississippi</Option>
                        <Option value="Missouri">Missouri</Option>
                        <Option value="Montana">Montana</Option>
                        {/* <Option value="Nevada(excluding Las Vegas)">Nevada(excluding Las Vegas)</Option> */}
                        <Option value="Nebraska">Nebraska</Option>
                        <Option value="Nevada">Nevada</Option>
                        <Option value="New Jersey">New Jersey</Option>
                        <Option value="New Mexico">New Mexico</Option>
                        {/* <Option value="North Carolina">North Carolina</Option> */}
                        <Option value="North Dakota">North Dakota</Option>
                        <Option value="Ohio">Ohio</Option>
                        <Option value="Oregon">Oregon</Option>
                        {/* <Option value="Pennsylvania">Pennsylvania</Option> */}
                        {/*<Option value="Rhode Island">Rhode Island</Option>*/}
                        <Option value="South Carolina">South Carolina</Option>
                        <Option value="South Dakota">South Dakota</Option>
                        <Option value="Tennessee">Tennessee</Option>
                        <Option value="Texas">Texas</Option>
                        <Option value="Utah">Utah</Option>
                        {/*<Option value="Vermont">Vermont</Option>*/}
                        {/*<Option value="West Virginia">West Virginia</Option>*/}
                        {/* <Option value="Washington">Washington</Option> */}
                        <Option value="Wisconsin">Wisconsin</Option>
                        <Option value="Wyoming">Wyoming</Option>
                      </Select>
                    </div>
                  </>
                ) : reInspection === "edit" ? (
                  <>
                    {stateEmpty === true ? (
                      <div
                        className="select-state-heading"
                        style={{ color: "red" }}
                      >
                        Select Inspection State
                      </div>
                    ) : (
                      <div className="select-state-heading">
                        Select Inspection State
                      </div>
                    )}

                    <div className="drop-down-container">
                      <Select
                        className="drop-down"
                        labelInValue
                        // defaultValue={{ value: 'Select State', color:'red'}}
                        placeholder={reInspectionState}
                        disabled={true}
                        onChange={(e) => {
                          handleState(e?.value);
                          setStateEmpty(false);
                        }}
                      >
                        <Option value="Alabama">Alabama</Option>
                        <Option value="Alaska">Alaska</Option>
                        <Option value="Arizona">Arizona</Option>
                        <Option value="Arkansas">Arkansas</Option>
                        <Option value="Bahamas">Bahamas</Option>
                        <Option value="Northern California (Bay Area)">
                          {" "}
                          Northern California (Bay Area)
                        </Option>
                        <Option value="Northern California (Sacramento Region)">
                          {" "}
                          Northern California (Sacramento Region)
                        </Option>
                        <Option value="Northern California (Other)">
                          Northern California (Other)
                        </Option>
                        <Option value="Central California">
                          Central California
                        </Option>
                        <Option value="Southern California (LA region)">
                          Southern California (LA Region)
                        </Option>
                        <Option value="Southern California (Orange County)">
                          {" "}
                          Southern California (Orange County)
                        </Option>
                        <Option value="Southern California (San Diego Region)">
                          {" "}
                          Southern California (San Diego Region)
                        </Option>
                        <Option value="Southern California (Other)">
                          Southern California (Other)
                        </Option>
                        <Option value="Colorado">Colorado</Option>
                        {/*<Option value="Connecticut">Connecticut</Option>*/}
                        {/* <Option value="Delaware">Delaware</Option> */}
                        <Option value="Florida">Florida</Option>
                        <Option value="Georgia">Georgia</Option>
                        {/*<Option value="Hawaii">Hawaii</Option>*/}
                        <Option value="Idaho">Idaho</Option>
                        {/* <Option value="Illinois">Illinois</Option> */}
                        <Option value="Indiana">Indiana</Option>
                        <Option value="Kentucky">Kentucky</Option>
                        <Option value="Kansas">Kansas</Option>
                        <Option value="Iowa">Iowa</Option>
                        {/*<Option value="Louisiana">Louisiana</Option>*/}
                        {/*<Option value="Maine">Maine</Option>*/}
                        <Option value="Michigan">Michigan</Option>
                        <Option value="Minnesota">Minnesota</Option>
                        <Option value="Mississippi">Mississippi</Option>
                        <Option value="Missouri">Missouri</Option>
                        <Option value="Montana">Montana</Option>
                        {/* <Option value="Nevada(excluding Las Vegas)">Nevada(excluding Las Vegas)</Option> */}
                        <Option value="Nebraska">Nebraska</Option>
                        <Option value="Nevada">Nevada</Option>
                        <Option value="New Jersey">New Jersey</Option>
                        <Option value="New Mexico">New Mexico</Option>
                        {/* <Option value="North Carolina">North Carolina</Option> */}
                        <Option value="North Dakota">North Dakota</Option>
                        <Option value="Ohio">Ohio</Option>
                        <Option value="Oregon">Oregon</Option>
                        {/* <Option value="Pennsylvania">Pennsylvania</Option> */}
                        {/*<Option value="Rhode Island">Rhode Island</Option>*/}
                        <Option value="South Carolina">South Carolina</Option>
                        <Option value="South Dakota">South Dakota</Option>
                        <Option value="Tennessee">Tennessee</Option>
                        <Option value="Texas">Texas</Option>
                        <Option value="Utah">Utah</Option>
                        {/*<Option value="Vermont">Vermont</Option>*/}
                        {/*<Option value="West Virginia">West Virginia</Option>*/}
                        {/* <Option value="Washington">Washington</Option> */}
                        <Option value="Wisconsin">Wisconsin</Option>
                        <Option value="Wyoming">Wyoming</Option>
                      </Select>
                    </div>
                  </>
                ) : reInspection === "addCompany" ? (
                  <>
                    {stateEmpty === true ? (
                      <div
                        className="select-state-heading"
                        style={{ color: "red" }}
                      >
                        Select Inspection State
                      </div>
                    ) : (
                      <div className="select-state-heading">
                        Select Inspection State
                      </div>
                    )}

                    <div className="drop-down-container">
                      <Select
                        className="drop-down"
                        labelInValue
                        // defaultValue={{ value: 'Select State', color:'red'}}
                        placeholder={reInspectionState}
                        disabled={true}
                        onChange={(e) => {
                          handleState(e?.value);
                          setStateEmpty(false);
                        }}
                      >
                        <Option value="Alabama">Alabama</Option>
                        <Option value="Alaska">Alaska</Option>
                        <Option value="Arizona">Arizona</Option>
                        <Option value="Arkansas">Arkansas</Option>
                        <Option value="Bahamas">Bahamas</Option>
                        <Option value="Northern California (Bay Area)">
                          {" "}
                          Northern California (Bay Area)
                        </Option>
                        <Option value="Northern California (Sacramento Region)">
                          {" "}
                          Northern California (Sacramento Region)
                        </Option>
                        <Option value="Northern California (Other)">
                          Northern California (Other)
                        </Option>
                        <Option value="Central California">
                          Central California
                        </Option>
                        <Option value="Southern California (LA region)">
                          Southern California (LA Region)
                        </Option>
                        <Option value="Southern California (Orange County)">
                          {" "}
                          Southern California (Orange County)
                        </Option>
                        <Option value="Southern California (San Diego Region)">
                          {" "}
                          Southern California (San Diego Region)
                        </Option>
                        <Option value="Southern California (Other)">
                          Southern California (Other)
                        </Option>
                        <Option value="Colorado">Colorado</Option>
                        {/*<Option value="Connecticut">Connecticut</Option>*/}
                        {/* <Option value="Delaware">Delaware</Option> */}
                        <Option value="Florida">Florida</Option>
                        <Option value="Georgia">Georgia</Option>
                        {/*<Option value="Hawaii">Hawaii</Option>*/}
                        <Option value="Idaho">Idaho</Option>
                        {/* <Option value="Illinois">Illinois</Option> */}
                        <Option value="Indiana">Indiana</Option>
                        <Option value="Kentucky">Kentucky</Option>
                        <Option value="Kansas">Kansas</Option>
                        <Option value="Iowa">Iowa</Option>
                        {/*<Option value="Louisiana">Louisiana</Option>*/}
                        {/*<Option value="Maine">Maine</Option>*/}
                        <Option value="Michigan">Michigan</Option>
                        <Option value="Minnesota">Minnesota</Option>
                        <Option value="Mississippi">Mississippi</Option>
                        <Option value="Missouri">Missouri</Option>
                        <Option value="Montana">Montana</Option>
                        {/* <Option value="Nevada(excluding Las Vegas)">Nevada(excluding Las Vegas)</Option> */}
                        <Option value="Nebraska">Nebraska</Option>
                        <Option value="Nevada">Nevada</Option>
                        <Option value="New Jersey">New Jersey</Option>
                        <Option value="New Mexico">New Mexico</Option>
                        {/* <Option value="North Carolina">North Carolina</Option> */}
                        <Option value="North Dakota">North Dakota</Option>
                        <Option value="Ohio">Ohio</Option>
                        <Option value="Oregon">Oregon</Option>
                        {/* <Option value="Pennsylvania">Pennsylvania</Option> */}
                        {/*<Option value="Rhode Island">Rhode Island</Option>*/}
                        <Option value="South Carolina">South Carolina</Option>
                        <Option value="South Dakota">South Dakota</Option>
                        <Option value="Tennessee">Tennessee</Option>
                        <Option value="Texas">Texas</Option>
                        <Option value="Utah">Utah</Option>
                        {/*<Option value="Vermont">Vermont</Option>*/}
                        {/*<Option value="West Virginia">West Virginia</Option>*/}
                        {/* <Option value="Washington">Washington</Option> */}
                        <Option value="Wisconsin">Wisconsin</Option>
                        <Option value="Wyoming">Wyoming</Option>
                      </Select>
                    </div>
                  </>
                ) : reInspection === "false" ? (
                  <>
                    {stateEmpty === true ? (
                      <div
                        className="select-state-heading"
                        style={{ color: "red" }}
                      >
                        Select Inspection State
                      </div>
                    ) : (
                      <div className="select-state-heading">
                        Select Inspection State
                      </div>
                    )}

                    <div className="drop-down-container">
                      <Select
                        className="drop-down"
                        labelInValue
                        // defaultValue={{ value: 'Select State', color:'red'}}
                        placeholder="Select State"
                        onChange={(e) => {
                          handleState(e?.value);
                          setStateEmpty(false);
                        }}
                      >
                        <Option value="Alabama">Alabama</Option>
                        <Option value="Alaska">Alaska</Option>
                        <Option value="Arizona">Arizona</Option>
                        <Option value="Arkansas">Arkansas</Option>
                        <Option value="Bahamas">Bahamas</Option>
                        <Option value="Northern California (Bay Area)">
                          {" "}
                          Northern California (Bay Area)
                        </Option>
                        <Option value="Northern California (Sacramento Region)">
                          {" "}
                          Northern California (Sacramento Region)
                        </Option>
                        <Option value="Northern California (Other)">
                          Northern California (Other)
                        </Option>
                        <Option value="Central California">
                          Central California
                        </Option>
                        <Option value="Southern California (LA region)">
                          Southern California (LA Region)
                        </Option>
                        <Option value="Southern California (Orange County)">
                          {" "}
                          Southern California (Orange County)
                        </Option>
                        <Option value="Southern California (San Diego Region)">
                          {" "}
                          Southern California (San Diego Region)
                        </Option>
                        <Option value="Southern California (Other)">
                          Southern California (Other)
                        </Option>
                        <Option value="Colorado">Colorado</Option>
                        {/*<Option value="Connecticut">Connecticut</Option>*/}
                        {/* <Option value="Delaware">Delaware</Option> */}
                        <Option value="Florida">Florida</Option>
                        <Option value="Georgia">Georgia</Option>
                        {/*<Option value="Hawaii">Hawaii</Option>*/}
                        <Option value="Idaho">Idaho</Option>
                        {/* <Option value="Illinois">Illinois</Option> */}
                        <Option value="Indiana">Indiana</Option>
                        <Option value="Kentucky">Kentucky</Option>
                        <Option value="Kansas">Kansas</Option>
                        <Option value="Iowa">Iowa</Option>
                        {/*<Option value="Louisiana">Louisiana</Option>*/}
                        {/*<Option value="Maine">Maine</Option>*/}
                        <Option value="Michigan">Michigan</Option>
                        <Option value="Minnesota">Minnesota</Option>
                        <Option value="Mississippi">Mississippi</Option>
                        <Option value="Missouri">Missouri</Option>
                        <Option value="Montana">Montana</Option>
                        {/* <Option value="Nevada(excluding Las Vegas)">Nevada(excluding Las Vegas)</Option> */}
                        <Option value="Nebraska">Nebraska</Option>
                        <Option value="Nevada">Nevada</Option>
                        <Option value="New Jersey">New Jersey</Option>
                        <Option value="New Mexico">New Mexico</Option>
                        {/* <Option value="North Carolina">North Carolina</Option> */}
                        <Option value="North Dakota">North Dakota</Option>
                        <Option value="Ohio">Ohio</Option>
                        <Option value="Oregon">Oregon</Option>
                        {/* <Option value="Pennsylvania">Pennsylvania</Option> */}
                        {/*<Option value="Rhode Island">Rhode Island</Option>*/}
                        <Option value="South Carolina">South Carolina</Option>
                        <Option value="South Dakota">South Dakota</Option>
                        <Option value="Tennessee">Tennessee</Option>
                        <Option value="Texas">Texas</Option>
                        <Option value="Utah">Utah</Option>
                        {/*<Option value="Vermont">Vermont</Option>*/}
                        {/*<Option value="West Virginia">West Virginia</Option>*/}
                        {/* <Option value="Washington">Washington</Option> */}
                        <Option value="Wisconsin">Wisconsin</Option>
                        <Option value="Wyoming">Wyoming</Option>
                      </Select>
                    </div>
                  </>
                ) : null}
                {/* {numberEmpty === true ? (
                    <input type="text" placeholder="Enter licensePlateNumber first (s)" className="license-plate-error-text" onChange={() => setNumberEmpty(false)} />
                  ) : ( */}
                {/* )} */}
                <div>
                  {checkEmpty === true ? (
                    <div
                      className="select-company-heading"
                      style={{ color: "red" }}
                    >
                      Select Company (s)
                    </div>
                  ) : (
                    <div className="select-company-heading">
                      Select Company (s)
                    </div>
                  )}
                  <div className="select-company-checks_container">
                    {inspectionState === "" && reInspection === "false" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "300px",
                        }}
                      >
                        Select Inspection State First
                      </div>
                    ) : reInspection === "edit" ? (
                      <>
                        <div style={{ marginBottom: 35 }}>
                          {stateCompanies?.map((company) => {
                            return (
                              <>
                                {company?.displayOnFront === true ? (
                                  <div
                                    className="select-company-checkbox-container"
                                    onClick={() => {
                                      handleInspectionCheck(company?.id);
                                      setCheckEmpty(false);
                                    }}
                                    key={company?.id}
                                  >
                                    <>
                                      <div className="company-name-text">
                                        {company?.name}
                                      </div>
                                      {inspectionChecked.includes(
                                        company?.id
                                      ) ? (
                                        <TiTick
                                          className="tick-background-color"
                                          color="#FF7C00"
                                          size={22}
                                        />
                                      ) : (
                                        <div className="tick-background-color"></div>
                                      )}
                                    </>
                                  </div>
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : reInspection === "addCompany" ? (
                      <>
                        <div style={{ marginBottom: 35 }}>
                          {filteredCompanies()?.map((company) => {
                            return (
                              <>
                                <div
                                  className="select-company-checkbox-container"
                                  onClick={() => {
                                    handleInspectionCheck(company?.id);
                                    setCheckEmpty(false);
                                  }}
                                  key={company?.id}
                                >
                                  <>
                                    <div className="company-name-text">
                                      {company?.name}
                                    </div>
                                    {inspectionChecked.includes(company?.id) ? (
                                      <TiTick
                                        className="tick-background-color"
                                        color="#FF7C00"
                                        size={22}
                                      />
                                    ) : (
                                      <div className="tick-background-color"></div>
                                    )}
                                  </>
                                </div>
                              </>
                            );
                          })}

                          {filteredCompaniesSelected()?.map((company) => {
                            return (
                              <>
                                <div
                                  className="select-company-checkbox-container"
                                  key={company?.id}
                                >
                                  <>
                                    <div
                                      className="company-name-text"
                                      style={{ color: "gray" }}
                                    >
                                      {company?.name}
                                    </div>

                                    <TiTick
                                      className="tick-background-color"
                                      color="gray"
                                      size={22}
                                    />
                                  </>
                                </div>
                              </>
                            );
                          })}
                          {/*{stateCompanies?.map((company) => {*/}
                          {/*  return (*/}
                          {/*      <>*/}
                          {/*        {company?.displayOnFront === true ? (*/}
                          {/*            <div*/}
                          {/*                className="select-company-checkbox-container"*/}
                          {/*                onClick={() => {*/}
                          {/*                  handleInspectionCheck(company?.id);*/}
                          {/*                  setCheckEmpty(false);*/}
                          {/*                }}*/}
                          {/*                key={company?.id}*/}
                          {/*            >*/}
                          {/*              <>*/}
                          {/*                <div className="company-name-text">{company?.name}</div>*/}
                          {/*                {inspectionChecked.includes(company?.id) ? (*/}
                          {/*                    <TiTick className="tick-background-color" color="#FF7C00" size={22} />*/}
                          {/*                ) : (*/}
                          {/*                    <div className="tick-background-color"></div>*/}
                          {/*                )}*/}
                          {/*              </>*/}
                          {/*            </div>*/}
                          {/*        ) : null}*/}
                          {/*      </>*/}
                          {/*  );*/}
                          {/*})}*/}
                        </div>
                      </>
                    ) : loadingcompny ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "300px",
                        }}
                      >
                        <ClipLoader color={"#246DB5"} size={20} />
                      </div>
                    ) : (
                      <div style={{ marginBottom: 35 }}>
                        {stateCompanies?.map((company) => {
                          return (
                            <>
                              {company?.displayOnFront === true ? (
                                <div>
                                  <div
                                    className="select-company-checkbox-container"
                                    onClick={() => {
                                      handleInspectionCheck(company?.id);
                                      setCheckEmpty(false);
                                    }}
                                    key={company?.id}
                                  >
                                    <>
                                      <div className="company-name-text">
                                        {company?.name}
                                      </div>

                                      {inspectionChecked.includes(
                                        company?.id
                                      ) ? (
                                        <TiTick
                                          className="tick-background-color"
                                          color="#FF7C00"
                                          size={22}
                                        />
                                      ) : (
                                        <div className="tick-background-color"></div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="inpection-instruction-btn-container">
                  {reInspection === "true" ? (
                    <>
                      {inspectionChecked.length !== 0 &&
                      checkEmpty === false ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => handleCreateInspection(reInspection)}
                          >
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "21px",
                                }}
                              >
                                <ClipLoader color={"#246DB5"} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : reInspection === "edit" ? (
                    <>
                      {inspectionChecked.length !== 0 &&
                      checkEmpty === false ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => handleEditInspection(reInspection)}
                          >
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "21px",
                                }}
                              >
                                <ClipLoader color={"#246DB5"} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : reInspection === "addCompany" ? (
                    <>
                      {inspectionChecked.length !== 0 &&
                      checkEmpty === false ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => handleAddNewCompany(reInspection)}
                          >
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "21px",
                                }}
                              >
                                <ClipLoader color={"#246DB5"} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : reInspection === "false" ? (
                    <>
                      {inspectionChecked.length !== 0 &&
                      checkEmpty === false &&
                      numberEmpty === false &&
                      licensePlateNumber !== "" &&
                      inspectionState !== "" &&
                      stateEmpty === false ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => handleCreateInspection(reInspection)}
                          >
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "21px",
                                }}
                              >
                                <ClipLoader color={"#246DB5"} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : inspectionChecked.length !== 0 &&
                        inspectionState !== "" &&
                        licensePlateNumber === "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setNumberEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : inspectionChecked.length === 0 &&
                        inspectionState !== "" &&
                        licensePlateNumber !== "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : inspectionChecked.length !== 0 &&
                        inspectionState === "" &&
                        licensePlateNumber !== "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setStateEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : inspectionChecked.length === 0 &&
                        inspectionState === "" &&
                        licensePlateNumber !== "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                              setStateEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : inspectionChecked.length === 0 &&
                        inspectionState !== "" &&
                        licensePlateNumber === "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                              setNumberEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : inspectionChecked.length !== 0 &&
                        inspectionState === "" &&
                        licensePlateNumber === "" ? (
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setStateEmpty(true);
                              setNumberEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) : (
                        // ) : inspectionChecked.length === 0 && inspectionState === "" && licensePlateNumber !== '' ? (
                        //   <div className="inspection-btn-align">
                        //     <div
                        //       className="inspection-btn-container"
                        //       onClick={() => { setCheckEmpty(true); setStateEmpty(true); }}
                        //     >
                        //       <div className="select-text">Select</div>
                        //     </div>
                        //   </div>
                        // ) : inspectionChecked.length === 0 && inspectionState !== "" && licensePlateNumber === '' ? (
                        //   <div className="inspection-btn-align">
                        //     <div
                        //       className="inspection-btn-container"
                        //       onClick={() => { setCheckEmpty(true); setNumberEmpty(true)}}
                        //     >
                        //       <div className="select-text">Select</div>
                        //     </div>
                        //   </div>
                        // ) : inspectionChecked.length !== 0 && inspectionState === "" && licensePlateNumber === '' ? (
                        //   <div className="inspection-btn-align">
                        //     <div
                        //       className="inspection-btn-container"
                        //       onClick={() => { setCheckEmpty(true); }}
                        //     >
                        //       <div className="select-text">Select</div>
                        //     </div>
                        //   </div>
                        <div className="inspection-btn-align">
                          <div
                            className="inspection-btn-container"
                            onClick={() => {
                              setCheckEmpty(true);
                              setNumberEmpty(true);
                              setStateEmpty(true);
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>

              <LogoutModal
                logout={false}
                loading={submitLoading}
                modal={alreadyExistModal}
                setLoading={setSubmitLoading}
                setModal={setAlreadyExistModal}
                handleDeny={handleAutoReInspection}
                handleSubmit={handleSubmitAlreadyExist}
                errorMessage={inspectionErrorResp?.errorMessage}
              />
              <Modal
                title="Basic Modal"
                visible={showTuroModal}
                centered={true}
              >
                <div className="veh-inspection-verification__formtext ">
                  {/* Turo */}
                </div>
                <div
                  className="inspection--card-close_icon"
                  onClick={() => closeTuroModal()}
                >
                  <IoClose color="#fff" size={19} />
                </div>
                <Form
                  style={{ marginTop: "40px" }}
                  id="myForm"
                  requiredMark={false}
                  layout="vertical"
                  onFinish={handleYearSubmit}
                >
                  <Form.Item
                    name="year"
                    label="Enter Vehicle Year"
                    rules={[
                      { required: true, message: "Please enter a year" },
                      { min: 4, message: "Invalid Year Format" },
                      { max: 4, message: "Invalid Year Format" },
                    ]}
                  >
                    <Input
                      placeholder="Year"
                      disabled={showErrorMessage}
                      required
                    />
                  </Form.Item>

                  <div>
                    {!showErrorMessage ? (
                      <button
                        type="submit"
                        className="vec-inspection-Edit__button"
                      >
                        Submit
                      </button>
                    ) : null}

                    {showErrorMessage ? (
                      <>
                        <div className="turoErrorMsg">
                          <b>Note! </b>
                          Vehicles that are 5 years old or older, Turo requires
                          battery check. Your vehicle does not qualify for a
                          remote inspection for Turo. Please check with
                          your local mechanic.
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            type="submit"
                            className="turoModalCloseButton"
                            onClick={() => closeTuroModal()}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="turoModalEditButton"
                            onClick={() => editTuruYear()}
                          >
                            Edit
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </Form>
              </Modal>
            </ContentFooterareaInspectionInstruction>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
    </MainContainer>
  );
};
export default SelectCompanyScreen;
