import React from "react";
import "./style.css";

const PricingCard = (props) => {
  return (
    <div className="priceCardContainer">
      <div
        className="pricetagBg_new"
        style={{ backgroundColor: props.colorCode }}
      >
        <p className="pricetagText_new">{props.tagText}</p>
      </div>
      {/*<div className="cardSecondContainer">*/}

      <h3 className="pricecardHeading" style={{ color: props.priceColor }}>
        <span className="lyftDiscount">
          <del className="amount" style={{ color: props.priceColor }}>
            {props.priceText}
          </del>
        </span>
      </h3>

      <h3
        className="discountPricecardHeading"
        style={{ color: props.priceColor }}
      >
        {props.DiscountPriceText}
      </h3>

      <p className="pricecardParagraph">{props.ParagraphText}</p>

      {/*</div>/\*/}
    </div>
  );
};

export default PricingCard;
