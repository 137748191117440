import React, {useEffect, useRef, useState} from "react";
import {ChexLandingScreen, HomeLandingScreen} from "../../Screens";
import { bindActionCreators } from "redux";
import ActionCreators from "../../actions";
import {connect, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useMediaQuery } from "react-responsive";
import LandingBannerComponent from "../../Components/LandingBannerComponent";

const HomeLandingContainer = (props) => {

  const history = useHistory();
  const { addToast } = useToasts();
  const [checkboxValue, setCheckBox] = useState(false);


  const handleCheckBox = () => {
    setCheckBox(!checkboxValue);
  };

  const handleSubmit = (params) => {
    const { register } = props;
    if (params?.phone.includes("_")) {
      addToast("Please enter a valid number", { appearance: "error" });
    } else {
      if (checkboxValue) {
        register(params, history, addToast);
      } else {
        addToast("Please accept term of use", { appearance: "warning" });
      }
    }
  };

  const handleLoginRedirect = () => {
    window.location.href = "/login";
  }

  const handleReDirect = () => {
    window.location.href = "/getStarted";
  };

  const handleContactReDirect = () => {
    window.location.href = "/contactPage";
  };
  const aboutBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 370, itemsToShow: 1, itemsToScroll: 1 },
    { width: 458, itemsToShow: 2 },
    { width: 868, itemsToShow: 3 },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 650, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];

  const handleTermsOfUse = () => {
    window.location.href = "/termsAndPolicy?home=true";
  };

  const isBigScreen = useMediaQuery({ query: "(min-width: 1108px)" });
  const isTabScreen = useMediaQuery({ query: "(min-width: 575px)" });
  const isMobileScreen = useMediaQuery({ query: "(min-width: 200px)" });
  const carouselRef = useRef(null);

  const changeHanlder = (currentItemObject, currentPageIndex) => {
    if (currentItemObject.index === 2 && isBigScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
    if (currentItemObject.index === 3 && isTabScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }

    if (currentItemObject.index === 4 && isMobileScreen) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  return (
    // <HomeLandingScreen
    //   handleTermsOfUse={handleTermsOfUse}
    //   handleLoginRedirect={handleLoginRedirect}
    //   breakPoints={breakPoints}
    //   handleReDirect={handleReDirect}
    //   handleContactReDirect={handleContactReDirect}
    //   handleSubmit={handleSubmit}
    //   handleCheckBox={handleCheckBox}
    //   changeHanlder={changeHanlder}
    //   carouselRef={carouselRef}
    //   isLoading={props.isLoading}
    //   about_breakPoints={aboutBreakPoints}
    // />
      <ChexLandingScreen
          changeHanlder={changeHanlder}
          carouselRef={carouselRef}
          about_breakPoints={aboutBreakPoints}
          breakPoints={breakPoints}

      />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isAuthLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeLandingContainer);
