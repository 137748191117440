import React, { useState } from "react";
import Moment from "react-moment";

import { GrFormClose } from "react-icons/gr";
import { Modal } from "antd";

import DownloadCertifcate from "../../Screens/Certificates/DownLoadCertificate";
import UberCertificate from "../../Screens/Certificates/UberCertificate";
import UberCertificateScNcGe from "../../Screens/Certificates/UberCertificateScNcGe";
import TuroCertificate from "../../Screens/Certificates/TuroCertificate";
import HopeSkipDriveCertificate from "../../Screens/Certificates/HopeSkipDriveCertificate";

import Lyftcertificate from "../../Screens/Certificates/Lyftcertificate";
import LyftCertificateArizona from "../../Screens/Certificates/LyftCertificateArizona";
import LyftCertificateSouthCarolina from "../../Screens/Certificates/LyftCertificateSouthCarolina";
import LyftCertificateNevada from "../../Screens/Certificates/LyftCertificateNevada";
import LyftCertificateMichigan from "../../Screens/Certificates/LyftCertificateMichigan";
import LyftCertificateColorado from "../../Screens/Certificates/LyftCertificateColorado";

import "./style.css";
import "../../App.css";
import { GetAroundCertificate } from "../../Screens";

const VehicleCertificateTileComponent = ({ item }) => {
  const [company, setCompany] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [iscertificateLoading, setisCertificateLoading] = useState(false);
  const [certificateData, setCertificateData] = useState({
    id: "",
    companyId: "",
    companyName: "",
    templateId: "",
    url: "",
  });

  const showCompanyModal = () => {
    setCompanyModalVisible(true);
  };

  const handleOkCompany = () => {
    setCompanyModalVisible(false);
  };
  const handlecertificateDownload = () => {
    const url = certificateData?.url;
    if (url) {
      const Url = `${process.env.REACT_APP_AWS_S3_LINK}/${url}?x-request=xhr`;
      fetch(Url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobURL;
          link.download = "Vehicle-certificate.png";
          link.click();
          URL.revokeObjectURL(blobURL);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {});
    }
  };
  const handleCancelCompany = () => {
    setCompanyModalVisible(false);
    setCompany(false);
    setCertificateData({
      id: "",
      companyId: "",
      companyName: "",
      templateId: "",
      url: "",
    });
    window.location.reload();
  };

  const handleDownload = (items, id) => {
    setCompany(true);
    setCertificateData({
      id: id,
      companyId: items?.Company?.id,
      companyName: items?.Company?.name,
      templateId: items?.Company?.templateId,
      url: items?.certificateUrl,
    });
  };

  return (
    // <div style={{overflowY:'scroll',height:'72%'}}>

    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="certificates-tile">
        <div className="certificates-tile-content">
          <div>
            <div className="certificates-tracking-id-text">
              {/* Tracking ID */}
              License Plate No.
            </div>
            <div className="certificates-tracking-id">
              {/* {item?.inspectionCode} */}
              {item?.Vehicle?.licensePlateNumber}
            </div>
          </div>
          <div>
            <div className="certificates-date-created">Date Created</div>
            <div className="certificates-date">
              <Moment format="MM/DD/YYYY">{item?.createdAt}</Moment>
            </div>
          </div>
          {/* <div>
            <div className="certificates-company">
            Company(s)
            </div>
            <div className="certificates-company-name">
              {companies?.Company?.name}
            </div>
            </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="certificates-button"
            onClick={() => {
              showCompanyModal();
            }}
          >
            Download Certificate{" "}
          </button>
        </div>
        <Modal
          style={{ width: "40px", height: "40px" }}
          title="Basic Modal"
          visible={companyModalVisible}
          onOk={handleOkCompany}
          onCancel={() => {
            handleCancelCompany();
          }}
        >
          <div className="companies-modal">
            <div className="modal-content-container">
              <div></div>
              <div className="option-text">Companies</div>
              <GrFormClose
                color="black"
                size={25}
                onClick={() => {
                  handleCancelCompany();
                }}
              />
            </div>
            {item?.CompanyInspections?.map((items) => {
              return (
                <div className="modal-company-text" key={items?.id}>
                  <div className="modal-links-text">{items?.Company?.name}</div>
                  <input
                    className="checkbox-container"
                    type="radio"
                    name="name"
                    onClick={() => {
                      handleDownload(items, item?.id);
                    }}
                  />
                </div>
              );
            })}

            {company === true ? (
              certificateData?.templateId === 4 ? (
                <>
                  {item?.inspectionState &&
                  certificateData?.companyName?.toLowerCase() === "turo" ? (
                    <>
                      <TuroCertificate
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    </>
                  ) : null}
                </>
              ) : certificateData?.companyName === "getaround" ? (
                <GetAroundCertificate
                  SetModal={setCompanyModalVisible}
                  handleModal={handleCancelCompany}
                  setLoading={setisCertificateLoading}
                  isLoading={iscertificateLoading}
                  companyId={certificateData?.companyId}
                  id={certificateData?.id}
                />
              ) : certificateData?.templateId === 3 ? (
                <>
                  {item?.inspectionState?.toLowerCase() === "california" ||
                  item?.inspectionState === "Northern California (Bay Area)" ||
                  item?.inspectionState ===
                    "Northern California (Sacramento Region)" ||
                  item?.inspectionState === "Northern California (Other)" ||
                  item?.inspectionState === "Central California" ||
                  item?.inspectionState === "Southern California (LA region)" ||
                  item?.inspectionState ===
                    "Southern California (Orange County)" ||
                  item?.inspectionState ===
                    "Southern California (San Diego Region)" ||
                  (item?.inspectionState === "Southern California (Other)" &&
                    certificateData?.companyName?.toLowerCase() === "lyft") ? (
                    <>
                      <Lyftcertificate
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    </>
                  ) : item?.inspectionState?.toLowerCase() ===
                      "south carolina" &&
                    certificateData?.companyName?.toLowerCase() === "lyft" ? (
                    <>
                      <LyftCertificateSouthCarolina
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    </>
                  ) : item?.inspectionState?.toLowerCase() === "nevada" &&
                    certificateData?.companyName?.toLowerCase() === "lyft" ? (
                    <>
                      <LyftCertificateNevada
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    </>
                  ) : item?.inspectionState?.toLowerCase() === "michigan" &&
                    certificateData?.companyName?.toLowerCase() === "lyft" ? (
                    <LyftCertificateMichigan
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  ) : item?.inspectionState?.toLowerCase() === "colorado" &&
                    certificateData?.companyName?.toLowerCase() === "lyft" ? (
                    <LyftCertificateColorado
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  ) : (
                    <LyftCertificateArizona
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  )}
                </>
              ) : certificateData?.templateId === 2 ? (
                <>
                  {item?.inspectionState &&
                  certificateData?.companyName?.toLowerCase() ===
                    "hopskipdrive" ? (
                    <HopeSkipDriveCertificate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  ) : (item?.inspectionState?.toLowerCase() ===
                      "south carolina" ||
                      item?.inspectionState?.toLowerCase() ===
                        "north carolina" ||
                      item?.inspectionState?.toLowerCase() === "georgia") &&
                    certificateData?.companyName?.toLowerCase() === "uber" ? (
                    <>
                      {certificateData?.url ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div
                            onClick={handlecertificateDownload}
                            style={{
                              fontSize: "15px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              color: "white",
                              fontWeight: "bold",
                              margin: "auto",
                              background: "#3276ba",
                              padding: "13px",
                              borderRadius: "50px",
                              cursor: "pointer",
                            }}
                          >
                            Download vehicle report
                          </div>
                        </div>
                      ) : (
                        <UberCertificateScNcGe
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setisCertificateLoading}
                          isLoading={iscertificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      )}
                    </>
                  ) : certificateData?.companyName === "Uber" ||
                    certificateData?.companyName === "uber" ||
                    certificateData?.companyName === "Everdriven" ? (
                    <>
                      {certificateData?.url ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div
                            onClick={handlecertificateDownload}
                            style={{
                              fontSize: "15px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              color: "white",
                              fontWeight: "bold",
                              margin: "auto",
                              background: "#3276ba",
                              padding: "13px",
                              borderRadius: "50px",
                              cursor: "pointer",
                            }}
                          >
                            Download vehicle report
                          </div>
                        </div>
                      ) : (
                        <UberCertificate
                          SetModal={setCompanyModalVisible}
                          handleModal={handleCancelCompany}
                          setLoading={setisCertificateLoading}
                          isLoading={iscertificateLoading}
                          companyId={certificateData?.companyId}
                          id={certificateData?.id}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <DownloadCertifcate
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    </>
                  )}
                </>
              ) : certificateData?.templateId.length ? (
                <>
                  <DownloadCertifcate
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                </>
              ) : null
            ) : null}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default VehicleCertificateTileComponent;
