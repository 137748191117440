/* eslint-disable */

import React, { useState } from "react";
import { useHistory } from "react-router";
import { UpCircleOutlined } from "@ant-design/icons";
import { Modal, Collapse, Row, Col } from "antd";
import Moment from "react-moment";
import { GrFormClose } from "react-icons/gr";
import { IoDocumentTextOutline } from "react-icons/io5";

import "./style.css";
import "../../App.css";

import DownloadCertifcate from "../../Screens/Certificates/DownLoadCertificate";
import UberCertificate from "../../Screens/Certificates/UberCertificate";
import UberCertificateScNcGe from "../../Screens/Certificates/UberCertificateScNcGe";
import TuroCertificate from "../../Screens/Certificates/TuroCertificate";
import HopeSkipDriveCertificate from "../../Screens/Certificates/HopeSkipDriveCertificate";

import Lyftcertificate from "../../Screens/Certificates/Lyftcertificate";
import LyftCertificateArizona from "../../Screens/Certificates/LyftCertificateArizona";
import LyftCertificateSouthCarolina from "../../Screens/Certificates/LyftCertificateSouthCarolina";
import LyftCertificateNevada from "../../Screens/Certificates/LyftCertificateNevada";
import LyftCertificateMichigan from "../../Screens/Certificates/LyftCertificateMichigan";
import LyftCertificateColorado from "../../Screens/Certificates/LyftCertificateColorado";

import CarIcon from "../../Assets/caricons.svg";
import { GetAroundCertificate } from "../../Screens";

const ReviewedTileComponent = ({
  item,
  genExtra,
  handleNewCompany,
  handleVehicleDetails,
}) => {
  const { Panel } = Collapse;
  const history = useHistory();

  const [company, setCompany] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [iscertificateLoading, setisCertificateLoading] = useState(false);
  const [certificateData, setCertificateData] = useState({
    id: "",
    companyId: "",
    companyName: "",
    templateId: "",
    url: "",
  });

  const inspectionData = {
    vehicleId: item?.Vehicle?.id,
    plateNumber: item?.Vehicle?.licensePlateNumber,
    state: item?.inspectionState,
  };

  const showModalCompany = () => {
    setCompanyModalVisible(true);
  };

  const handleOkCompany = () => {
    setCompanyModalVisible(false);
  };
  // const handleReloadPage=()=>
  // {
  //   window.location.reload()
  // }
  const handlecertificateDownload = () => {
    const url = certificateData?.url;
    if (url) {
      const Url = `${process.env.REACT_APP_AWS_S3_LINK}/${url}?x-request=xhr`;
      fetch(Url)
        .then((response) => response.blob())
        .then((blob) => {
          var url = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = url;
          link.download = "vehicle certificate.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          // handleReloadPage()
        })
        .catch((error) => {});
    }
  };
  const handleCancelCompany = () => {
    setCompanyModalVisible(false);
    setCompany(false);
    setCertificateData({
      id: "",
      companyId: "",
      companyName: "",
      templateId: "",
      url: "",
    });
    window.location.reload();
  };

  const handleDownload = (items, id) => {
    
    setCompany(true);
    setCertificateData({
      id: id,
      companyId: items?.Company?.id,
      companyName: items?.Company?.name,
      templateId: items?.Company?.templateId,
      url: items?.certificateUrl,
    });
  };

  // const handleVehicleDetails = (item) => {
  //   history.push(`/VehicleAfterReviewing/${item?.id}/${item?.vehicleId}`);
  // };

  // const handleRedirect = (item) => {
  //   window.location.href = `/vehicleinspection/${item?.id}/${item?.vehicleId}?lyftUser=${item.lyftInspection}`;
  // };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <UpCircleOutlined
            style={
              isActive
                ? {
                    fontSize: 28,
                    color: "#FF7A00",
                    marginTop: 10,
                    marginRight: 2,
                  }
                : {
                    fontSize: 28,
                    color: "#FF7A00",
                    marginTop: 10,
                    marginRight: 2,
                  }
            }
            rotate={isActive ? 180 : 0}
          />
        )}
        className="submitted-tile"
      >
        <div className="submitted-tile-content">
          <div>
            <div className="submitted-tracking-id-text">License Plate No.</div>
            <div className="submitted-tracking-id">
              {item?.Vehicle?.licensePlateNumber}
              {/* {"Reviewed"} */}
            </div>
          </div>
          <div>
            <div className="submitted-date-created">Date Created</div>
            <div className="submitted-date">
              <Moment format="MM/DD/YYYY">{item?.createdAt}</Moment>
            </div>
          </div>
        </div>
        <div className="submitted-tile-tag">Reviewed</div>

        <Panel
          style={{ overflow: "hidden" }}
          header={
            <Row gutter={40}>
              <Col>
                <div className="reviewed-all-text"></div>
              </Col>
              <Col></Col>
            </Row>
          }
          key="1"
          extra={genExtra}
        >
          <div className="document-container">
            <IoDocumentTextOutline color="#1468BA" size={18} />
            <div className="document-text" onClick={() => showModalCompany()}>
              Download Certificate
            </div>
          </div>
          <div className="document-container">
            <img src={CarIcon} className="car-icon-style" />
            <div
              className="document-text"
              onClick={() => {
                history.push(`/selectCompany/${true}`, inspectionData);
              }}
            >
              Re-Inspect Car
            </div>
          </div>
          <div className="document-container">
            <img src={CarIcon} className="car-icon-style" />
            <div
              className="document-text"
              onClick={() => {
                handleVehicleDetails(item);
              }}
            >
              Car Details
            </div>
          </div>

          <div className="document-container">
            <img src={CarIcon} className="car-icon-style" />
            <div
              className="document-text"
              onClick={() => {
                handleNewCompany(item);
              }}
            >
              Add New Company
            </div>
          </div>
        </Panel>
      </Collapse>

      <Modal
        // style={{ width: '40px', height: '40px' }}
        title="Basic Modal"
        visible={companyModalVisible}
        onOk={handleOkCompany}
        onCancel={() => {
          handleCancelCompany();
        }}
      >
        <div className="companies-modal">
          <div className="modal-content-container">
            <div></div>
            <div className="option-text">Companies</div>
            <GrFormClose
              color="black"
              size={25}
              onClick={() => {
                handleCancelCompany();
              }}
            />
          </div>
          {item?.CompanyInspections?.map((items) => {
            return (
              <div className="modal-company-text" key={items?.id}>
                <div className="modal-links-text">{items?.Company?.name}</div>
                <input
                  className="checkbox-container"
                  type="radio"
                  name="name"
                  onClick={() => {
                    handleDownload(items, item?.id);
                  }}
                />
              </div>
            );
          })}
          {company === true ? (
            certificateData?.templateId === 4 ? (
              <>
                {item?.inspectionState &&
                certificateData?.companyName?.toLowerCase() === "turo" ? (
                  <>
                    <TuroCertificate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  </>
                ) : null}
              </>
            ) : certificateData?.templateId === 3 ? (
              <>
                {item?.inspectionState?.toLowerCase() === "california" ||
                item?.inspectionState === "Northern California (Bay Area)" ||
                item?.inspectionState ===
                  "Northern California (Sacramento Region)" ||
                item?.inspectionState === "Northern California (Other)" ||
                item?.inspectionState === "Central California" ||
                item?.inspectionState === "Southern California (LA region)" ||
                item?.inspectionState ===
                  "Southern California (Orange County)" ||
                item?.inspectionState ===
                  "Southern California (San Diego Region)" ||
                (item?.inspectionState === "Southern California (Other)" &&
                  certificateData?.companyName?.toLowerCase() === "lyft") ? (
                  <>
                    <Lyftcertificate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  </>
                ) : item?.inspectionState?.toLowerCase() === "south carolina" &&
                  certificateData?.companyName?.toLowerCase() === "lyft" ? (
                  <>
                    <LyftCertificateSouthCarolina
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  </>
                ) : item?.inspectionState?.toLowerCase() === "nevada" &&
                  certificateData?.companyName?.toLowerCase() === "lyft" ? (
                  <LyftCertificateNevada
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                ) : item?.inspectionState?.toLowerCase() === "michigan" &&
                  certificateData?.companyName?.toLowerCase() === "lyft" ? (
                  <LyftCertificateMichigan
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                ) : item?.inspectionState?.toLowerCase() === "colorado" &&
                  certificateData?.companyName?.toLowerCase() === "lyft" ? (
                  <LyftCertificateColorado
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                ) : (
                  <LyftCertificateArizona
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                )}
              </>
            ) : certificateData?.templateId === 2 ? (
              <>
                {item?.inspectionState &&
                certificateData?.companyName?.toLowerCase() ===
                  "hopskipdrive" ? (
                  <HopeSkipDriveCertificate
                    SetModal={setCompanyModalVisible}
                    handleModal={handleCancelCompany}
                    setLoading={setisCertificateLoading}
                    isLoading={iscertificateLoading}
                    companyId={certificateData?.companyId}
                    id={certificateData?.id}
                  />
                ) : (item?.inspectionState?.toLowerCase() ===
                    "south carolina" ||
                    item?.inspectionState?.toLowerCase() === "north carolina" ||
                    item?.inspectionState?.toLowerCase() === "georgia") &&
                  certificateData?.companyName?.toLowerCase() === "uber" ? (
                  <>
                    {certificateData?.url ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={handlecertificateDownload}
                          style={{
                            fontSize: "15px",
                            marginBottom: "20px",
                            marginTop: "20px",
                            color: "white",
                            fontWeight: "bold",
                            margin: "auto",
                            background: "#3276ba",
                            padding: "13px",
                            borderRadius: "50px",
                            cursor: "pointer",
                          }}
                        >
                          Download vehicle report
                        </div>
                      </div>
                    ) : (
                      <UberCertificateScNcGe
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    )}
                  </>
                ) : certificateData?.companyName === "Uber" ||
                  certificateData?.companyName === "uber" ||
                  certificateData?.companyName === "Everdriven" ? (
                  <>
                    {certificateData?.url ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={handlecertificateDownload}
                          style={{
                            fontSize: "15px",
                            marginBottom: "20px",
                            marginTop: "20px",
                            color: "white",
                            fontWeight: "bold",
                            margin: "auto",
                            background: "#3276ba",
                            padding: "13px",
                            borderRadius: "50px",
                            cursor: "pointer",
                          }}
                        >
                          Download vehicle report
                        </div>
                      </div>
                    ) : (
                      <UberCertificate
                        SetModal={setCompanyModalVisible}
                        handleModal={handleCancelCompany}
                        setLoading={setisCertificateLoading}
                        isLoading={iscertificateLoading}
                        companyId={certificateData?.companyId}
                        id={certificateData?.id}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <DownloadCertifcate
                      SetModal={setCompanyModalVisible}
                      handleModal={handleCancelCompany}
                      setLoading={setisCertificateLoading}
                      isLoading={iscertificateLoading}
                      companyId={certificateData?.companyId}
                      id={certificateData?.id}
                    />
                  </>
                )}
              </>
            ) : certificateData?.companyName === "getaround" ? (
              <GetAroundCertificate
                SetModal={setCompanyModalVisible}
                handleModal={handleCancelCompany}
                setLoading={setisCertificateLoading}
                isLoading={iscertificateLoading}
                companyId={certificateData?.companyId}
                id={certificateData?.id}
              />
            ) : certificateData?.templateId.length ? (
              <>
                <DownloadCertifcate
                  SetModal={setCompanyModalVisible}
                  handleModal={handleCancelCompany}
                  setLoading={setisCertificateLoading}
                  isLoading={iscertificateLoading}
                  companyId={certificateData?.companyId}
                  id={certificateData?.id}
                />
              </>
            ) : null
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default ReviewedTileComponent;
