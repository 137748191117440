/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { Drawer, Button, Grid } from 'antd';
import { Link } from "react-router-dom";

import './style.css'

const { useBreakpoint } = Grid;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const handleLogo =() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
}


const menu = (
    <Menu className="dropDown_newnav">
        <Menu.Item key="0" className=''>
            <span className="linkNavbar_wrap_dropdown">
                <Link to="/ride-share" style={{color:'white'}} className='contact-us-dropdown-content' onClick={handleLogo}>
                    Ride share
                </Link>
            </span>
        </Menu.Item>
        {/* <Menu.Item key="1" className='menu-items_newnav'>
            <span className="linkNavbar_wrap_dropdown">
                <Link to="/micro-mobility" style={{color:'white'}} className='contact-us-dropdown-content' onClick={handleLogo}>
                  Micromobility
                </Link>
            </span>
        </Menu.Item> */}
        <Menu.Item key="1" className='menu-items_newnav'>
            <span className="linkNavbar_wrap_dropdown">
                <Link to="/fleet-manager" style={{color:'white'}} className='contact-us-dropdown-content' onClick={handleLogo}>
                Fleet & DSP
                </Link>
            </span>
        </Menu.Item>
    </Menu>
);

const ContactUsNavbar = (props) => {
    const [visible, setVisible] = useState(false)
    const { md } = useBreakpoint()
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > props.scrollValue);
        });
    }, []);

    const showDrawer = () => {
        setVisible(true)
    };

    const onClose = () => {
        setVisible(false)
    };

    return (
        <nav className={`${scroll ? "ScrollBlackColor" : "null" } , menuBar-contact-us`}>

            {scroll ?
                <div className="logo">
                    <Link onClick={handleLogo}>
                    <img src="https://res.cloudinary.com/techlingcompany/image/upload/v1662448967/image_26_gx8xky.png" alt="Logo" />
                    </Link>
                </div>
                :
                <div className="logoWhite">
                    <Link onClick={handleLogo}>
                    <img src="https://res.cloudinary.com/techlingcompany/image/upload/v1662102750/Group_354logo_b0hqj7.png" alt="Logo" />
                    </Link>
                    </div>
            }
            {/* <div className="logo">
                {scroll ?
                <img  src={chexlogo} alt="Logo" />
                :
                <img  src={ChexIcon} alt="Logo" />
                }

            </div> */}
            <div className={`${scroll ? "menuContactWhite" : "menuContact" }`}>
                <div className="leftMenu">
                    <Menu className="menuNavbarMain" mode={md ? "horizontal" : "inline"}>
                        <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`}>
                                <Link to="/" onClick={handleLogo} >
                                    Home
                                </Link>
                            </span>
                        </li>
                        <Dropdown overlay={menu} className="my-menu-item my-menu-item-dropdown">
                            <a href='/' className="ant-dropdown-link-contact-us" onClick={e => e.preventDefault()}>
                                Services
                            </a>
                        </Dropdown>
                        {/* <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`}>
                                <Link to="/faqs" onClick={handleLogo}>
                                   FAQ
                                </Link>
                            </span>

                        </li> */}
                        <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`}>
                                <Link to="/blogPage" onClick={handleLogo}>
                                   Blog
                                </Link>
                            </span>

                        </li>
                        <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`}>
                                <Link to="/contactPage" onClick={handleLogo}>
                                    Contact Us
                                </Link>
                            </span>

                        </li>
                        {/* {window?.location?.pathname === "/contactPage" ? null : window?.location?.pathname === "/faqs" ? null :
                            <li className="my-menu-item">
                                <a href="#pricingSectionComplete">
                                    <span className="linkNavbar_wrap_pricing">
                                        Pricing
                                    </span>
                                </a>

                            </li>
                        } */}
                        <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`} onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}`, "_blank")}>
                                <Link to="/contactPage" >
                                    Dashboard
                                </Link>
                            </span>

                        </li>
                        <li className="my-menu-item">
                            <span >
                                <Link to="/login" >
                                    <span className={`${scroll ? "linkNavbar_wrap_white_login" : "linkNavbar_wrap_black_login" }`}> Login</span>
                                   
                                </Link>
                            </span>

                        </li>
                        <li className="my-menu-item">
                            <span className={`${scroll ? "linkNavbar_wrap_white" : "linkNavbar_wrap_black" }`}>
                                <Link to="/reqADemo" >
                                    <span className='req_a_demo'>Request A Demo</span>
                                    
                                </Link>
                            </span>

                        </li>
                    </Menu>

                </div>
                <Button className="barsMenu-black" type="#000" onClick={showDrawer}>
                    <span className="barsBtn"></span>
                </Button>
                <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    className='drawerMenu'
                >
                    <Menu style={{ color: 'white', backgroundColor: '#1B223B', height: '100%' }} mode={md ? "horizontal" : "inline"}>
                        {/* backgroundColor: '#1B223B' */}
                        <Menu.Item key="home">
                            <span className="linkNavbar_wrap">
                                <Link to="/LandingPage" style={{ color: '#fff' }} onClick={handleLogo}>
                                    Home
                                </Link>
                            </span>
                        </Menu.Item>
                        <SubMenu title='Services' >
                            <MenuItemGroup style={{ backgroundColor: '#1B223B', borderBottom: 'none' }}>
                                <Menu.Item style={{ color: '#fff' }} key="setting:1">
                                    <span className="linkNavbar_wrap">
                                        <Link to="/ride-share" style={{ color: '#fff' }} onClick={handleLogo}>
                                           Ride share
                                        </Link>
                                    </span>
                                </Menu.Item>
                                {/* <Menu.Item style={{ color: '#fff' }} key="setting:2">
                                    <span className="linkNavbar_wrap">
                                        <Link to="/micro-mobility" style={{ color: '#fff' }} onClick={handleLogo}>
                                           Micromobility
                                        </Link>
                                    </span>
                                </Menu.Item> */}
                                <Menu.Item style={{ color: '#fff' }} key="setting:2">
                                    <span className="linkNavbar_wrap">
                                        <Link to="/fleet-manager" style={{ color: '#fff' }} onClick={handleLogo}>
                                        Fleet & DSP
                                        </Link>
                                    </span>
                                </Menu.Item>
                            </MenuItemGroup>
                        </SubMenu>
                        {/* <Menu.Item key="contact">
                            <span className="linkNavbar_wrap">
                            <Link to="/faqs" style={{color: '#fff'}} onClick={handleLogo}>
                            FAQ
                            </Link>
                            </span>

                        </Menu.Item> */}
                        <Menu.Item key="contact">
                            <span className="linkNavbar_wrap">
                            <Link to="/blogPage" style={{color: '#fff'}} onClick={handleLogo}>
                           Blog
                            </Link>
                            </span>

                        </Menu.Item>
                        <Menu.Item key="contact">
                            <span className="linkNavbar_wrap">
                                <Link to="/contactPage" style={{ color: '#fff' }} onClick={handleLogo}>
                                    Contact Us
                                </Link>
                            </span>

                        </Menu.Item>
                        {/* {window?.location?.pathname === "/contactPage" ? null :
                            <Menu.Item key="pricing">
                                <a href="#pricingSectionComplete">
                                    <span className="linkNavbar_wrap_pricing">

                                        Pricing


                                    </span>
                                </a>

                            </Menu.Item>
                        } */}
                        <Menu.Item key="dashboard">
                            <span className="linkNavbar_wrap" onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}`, "_blank")}>
                                <Link to="/login" style={{ color: '#fff' }} >
                                    Dashboard
                                </Link>
                            </span>

                        </Menu.Item>
                        <Menu.Item key="login">
                            <span className="linkNavbar_wrap">
                                <Link to="/login" style={{ color: '#fff' }} >
                                    Login
                                </Link>
                            </span>

                        </Menu.Item>
                        <Menu.Item key="reqademo">
                            <span className="linkNavbar_wrap">
                                <Link to="/ReqADemo" style={{ color: '#fff' }} >
                                    Request a Demo
                                </Link>
                            </span>

                        </Menu.Item>
                    </Menu>
                </Drawer>
            </div>
        </nav>
    )
}
export default ContactUsNavbar;

