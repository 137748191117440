/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from '../../actions';

import VehicleCertificateScreen from '../../Screens/VehicleCertificateScreen';

const VehicleCertificateContainer = (props) => {
    const {
        getInspectionByStatus,
        vehiclesByStatus,
      } = props;
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        getInspectionByStatus( {status: ["REVIEWED"]},setLoading);
    },[])
    
  
    return (
        <VehicleCertificateScreen 
        loading={loading}
        vehiclesByStatus={vehiclesByStatus}
        />
    );
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
  }
  
  function mapStateToProps(state) {
    return {
      vehiclesByStatus: state?.vehicleInstruction?.inspectionByStatus,
      companies: state?.auth?.companies?.data,
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)(VehicleCertificateContainer);