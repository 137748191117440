import React from "react";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button } from "../../Components";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

import "../../Components/Input/style";
import "./style.css";

const ContactUsScreen = ({ handleSubmit, handleChange, loading , handleClick}) => {
  
  return (
    <div className="contact-us-main_container">
      <div className="contact-us-container">
        <div className="contact-us-screen_header">
          <Link to="/selectOption">
            <IoIosClose size={40} color="white" onClick={handleClick} />
          </Link>
        </div>
        <div className="contact-us-form_container">
          <p className="contact-us-text">Contact Us</p>
          <div className="input-fields-width">
            <input
              style={{ fontFamily: "Poppins" }}
              name="name"
              className="input-field"
              placeholder="Enter Full Name"
              onChange={handleChange}
            />
          </div>
          <div className="text-area-field">
            <textarea
              className="text-area-styling"
              rows="10"
              name="message"
              cols="50"
              placeholder="How can we help you?"
              onChange={handleChange}
            />
          </div>
          <Button
            loading={loading}
            title={"Submit"}
            // disabled={buttonDisable}
            onClickButton={() => handleSubmit()}
            buttonClass="contact-us-submit-btn"
          />
          <div className="or-reach-text">
            <b>or</b> <div className="or-line"></div>
          </div>
          <p className="directlt-reach-text">Email directly to:</p>
          <div className="email-container">
            {/* <AiOutlineMail size={24} color="#FF7A00" style={{ marginTop: '5px' }} /> */}
            <p className="email-text">support@chex.ai</p>
          </div>
          {/* <div className="email-container">
          <AiOutlinePhone size={24} color="#FF7A00" style={{ marginTop: '5px', marginLeft: -22 }} />
          <p className="email-text">(415) 234-3496</p>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
