/* eslint-disable */

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router';

import './style.css';
import '../../App.css';
import in_review from '../../Assets/in_review.png';

import { Header, VehicleCertificateTileComponent } from '../../Components';



const VehicleCertificateScreen = ({
  loading,
  vehiclesByStatus,
}) => {
  const history = useHistory();
  return (
    <div className="certificates-main-container">
      <div>
        <div style={{ marginBottom: '-13px' }}>
          <Header title={true} />
          <div className="certificates-conatiner">
            <div className="certificates-in-progress-text">Certificates</div>
            {vehiclesByStatus ? vehiclesByStatus.length != "0" ?
              <i>
                <div className="certificates-tag">
                  Please select from below to download the issued Certificate(s).
                </div>
              </i>
              : null : null
            }
            <div className="certificate-all-tiles">
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <ClipLoader color={'#246DB5'} size={20} />
                </div>) : vehiclesByStatus ? vehiclesByStatus.length != "0" ? vehiclesByStatus?.map((item) => {
                  return (
                    <VehicleCertificateTileComponent
                    key={item?.id}
                      item={item}
                    />
                  );
                }) : <div className="progress-container">
                  <img className="draft-image-show" src={in_review} />
                  <div className="draft-message-show">{'No Certificates Available'}</div>
                  <div className="check-inspection-text">(Please create an Inspections OR check Inspections in Progress for incomplete inspections)</div>
                </div> : null
              }
            </div>
            <div className="inspection-bottom-content">
              <div className="start-inspection-button">
                <div className="select-text" onClick={() => { history.push(`/selectCompany/${false}`) }}>+ Start Inspection</div>
              </div>
              <div className="back-text" onClick={() => { history.push(`/selectOption`) }}>Or Go back to <b className="home-text"> Home </b> page</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VehicleCertificateScreen;
