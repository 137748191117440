import React from "react";
import { Button } from "../../Components";
import "./style.css";
import "../../App.css";
import { IoIosArrowBack, BiEdit, GrClose } from "react-icons/all";
import { Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import percentImg from "../../../src/Assets/percentIcon.png";
import VerticalLine from "../../../src/Assets/Line.png";
import { ClipLoader } from "react-spinners";

const CheckoutScreen = ({
  inputElt,
  handlePromoData,
  // handleReferralData,
  submitPromo,
  // submitReferral,
  valuePromo,
  valueReferral,
  clearPromoValue,
  // clearReferralValue,
  clearPromoCode,
  // clearReferralCode,
  isCheckRadio,
  discountCheck,
  handleSubmit,
  loading,
  price,
  history,
  buttonDisable,
  priceLoading,
  handleSkipPayment,
  paymentStatusCheckout,
  changeVehicleStatus,
  vehicleStatusLoading,
  inspectionDataEdit,
  handlePaymentTransaction,
  isSurveyModalVisible,
  checkHasAddedData,
  hasAddedValue,
  mysterious,
  handleMysteroiusPayments,
}) => {
  return (
    <div className={`checkout-container`}>
      <div
        className={`CheckOut-container ${
          priceLoading ? "overlayLoader" : null
        }`}
      >
        {priceLoading ? (
          <div
            style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1 }}
          >
            <ClipLoader color={"#FF7A00"} />
          </div>
        ) : null}

        <div className="arrows_top_header">
          <div className="arrows_style">
            {/*<Link to="/vehicleinspection">*/}
            {checkHasAddedData === "Existing" ||
            hasAddedValue === "Existing" ? null : (
              <div
                onClick={() =>
                  history.push(
                    `/vehicleInspection/${inspectionDataEdit?.InspectionId}/${inspectionDataEdit?.VehicleId}`
                  )
                }
              >
                <IoIosArrowBack size={42} color="white" />
              </div>
            )}
            {/*</Link>*/}
          </div>
          <p className="chex_wrapper_main_text">
            CHEX.<span style={{ color: "#FF7A00" }}>AI</span>
          </p>
        </div>

        <div className="checkout-form-container">
          <div className="transaction-input-field">
            <div>
              <p className="checkout-payment-heading">Checkout</p>
            </div>

            {price?.discountCompanyName === false ? null : (
              <Card
                className={`upperCard ${isCheckRadio ? "isradio" : "noradio"}`}
                variant="outlined"
              >
                <div className="flexContainer" style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "inline",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={percentImg}
                      style={{
                        width: "43px",
                        height: "42px",
                        marginLeft: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                  <div style={{ display: "inline", marginLeft: "10px" }}>
                    <img
                      style={{ height: "55px", width: "2px" }}
                      src={VerticalLine}
                    />
                  </div>
                  <div style={{ paddingTop: "10px", fontFamily: "inter" }}>
                    <p
                      style={{
                        width: "95%",
                        marginLeft: "13px",
                        fontSize: "13px",
                        display: "inline-block",
                        color: "grey",
                        letterSpacing: "-0.01em",
                      }}
                    >
                      <span
                        style={{
                          color: "#FF0000",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                      >
                        Save $10
                      </span>{" "}
                      by adding <b>{price?.discountCompanyName}</b> to your
                      current{" "}
                      {price?.discountCompanyName === "lyft" ? "uber" : "lyft"}{" "}
                      inspection for only
                      <span
                        style={{
                          color: "#1F3B55",
                          fontSize: "14x",
                          fontWeight: "bold",
                          marginLeft: 5,
                        }}
                      >
                        $17.99
                      </span>
                    </p>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <Radio
                      size="large"
                      checked={isCheckRadio}
                      disabled={priceLoading ? true : false}
                      onClick={() =>
                        discountCheck(
                          price?.discountCompanyName === "lyft"
                            ? 11
                            : price?.discountCompanyName === "uber"
                            ? 7
                            : null
                        )
                      }
                      color="primary"
                      value="1"
                    ></Radio>
                  </div>
                </div>
              </Card>
            )}
          </div>
          <Card
            className="checkOutCard"
            style={{ marginTop: "20px", fontFamily: "Poppins" }}
            variant="outlined"
          >
            <div className="flexContainer">
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  fontSize: "16px",
                  letterSpacing: " -0.03em",
                  color: "#1F3B55",
                  fontWeight: "800",
                  display: "inline-block",
                }}
              >
                Multi Inspection
              </div>
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  marginTop: "10px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                <BiEdit
                  size={24}
                  color="#1468BA"
                  onClick={() => {
                    history.push(
                      `/selectCompany/${"edit"}`,
                      inspectionDataEdit
                    );
                  }}
                />
              </div>
            </div>

            <div
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                marginLeft: "10px",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                color: "#1262B1",
              }}
            >
              Companies Selected:{" "}
              {price?.multiCompanies?.map((data, index) => {
                return <>{(index ? ", " : "") + data}</>;
              })}
            </div>
            <div
              style={{
                marginTop: " 15px",
                marginRight: "10px",
                fontSize: "18px",
                letterSpacing: " -0.03em",
                color: "#FF7A00",
                fontWeight: "600",
                float: "right",
              }}
            >
              ${price.subtotal}
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                paddingTop: "35px",
              }}
            >
              <hr />
            </div>
            <div
              style={{
                fontFamily: "Poppins",
                paddingTop: "10px",
                marginLeft: "10px",
                fontSize: "16px",
                letterSpacing: " -0.03em",
                color: "#1F3B55",
                fontWeight: 600,
                marginTop: "-10px",
              }}
            >
              Promo Code
            </div>
            {price.promoCode === null || price.promoCode === undefined ? (
              <>
                <div className="flexContainr">
                  <div className="flex_block">
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        disabled={mysterious}
                        ref={inputElt}
                        className="inputPromoDesign"
                        placeholder={
                          mysterious ? "Mysteroius Company" : "Enter Code Here"
                        }
                        type="search"
                        value={valuePromo}
                        onChange={(e) => handlePromoData(e.target.value)}
                      />
                      <span style={{ position: "absolute", right: 8, top: 18 }}>
                        {valuePromo && (
                          <GrClose
                            className=" iconClear"
                            onClick={clearPromoValue}
                          />
                        )}
                      </span>
                    </div>
                    {/*<Input*/}
                    {/*    style={{marginLeft:"10px",width: "216px", backgroundColor: "#F1F1F1",borderRadius: "4px"}}*/}
                    {/*    size="large"*/}
                    {/*    allowClear />*/}
                  </div>
                  <div className="flex_block">
                    <div className="buttonWrapper_style">
                      <button
                        onClick={submitPromo}
                        disabled={valuePromo === "" ? true : false}
                        className="button_apply_style"
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <p>{valuePromo}</p>
                <p>
                  <GrClose
                    className="iconDelPromo"
                    onClick={() => clearPromoCode("")}
                  />
                </p>
              </div>
            )}

            {/* <div style={{fontFamily: 'Poppins', paddingTop:"10px",marginLeft: "10px",fontSize: "16px", letterSpacing:" -0.03em", color: "#1F3B55", fontWeight: 600, marginTop:"-10px"}}>Referral Code</div> */}
            {/* {price.referral === null || price.referral === undefined ?
                                        (
                                            <>

                                                <div className='flexContainr'>
                                                    <div className="flex_block">
                                                        <div style={{display: 'flex', position: 'relative', justifyContent: 'space-between'}}>

                                                            <input
                                                                ref={inputElt}
                                                                className='inputPromoDesign'
                                                                placeholder="Enter Code Here"
                                                                type='search'

                                                                value={valueReferral}
                                                                onChange={(e) =>handleReferralData(e.target.value)}
                                                            />
                                                            <span style={{position: 'absolute', right:8, top: 18}}>
                                    {valueReferral && <GrClose className=' iconClear' onClick={clearReferralValue} />}
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className='flex_block'>
                                                        <div className='buttonWrapper_style'>

                                                            <button
                                                                onClick={submitReferral}
                                                                disabled={valueReferral === '' ? true : false}
                                                                className="button_apply_style"
                                                            >
                                                                APPLY
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        ) :
                                        (
                                            <div style={{display: 'flex', justifyContent: 'space-between',marginTop:5, marginLeft: 10, marginRight: 10}}>
                                                <p>
                                                    {valueReferral}

                                                </p>
                                                <p>
                                                    <GrClose className='iconDelPromo'  onClick={ ()=> clearReferralCode('')} />
                                                </p>
                                            </div>
                                        )} */}
          </Card>
          <Card
            className="checkOutCard"
            style={{ marginTop: "20px" }}
            variant="outlined"
          >
            <div
              style={{
                marginLeft: "10px",
                paddingTop: "13px",
                fontSize: "16px",
                letterSpacing: " -0.03em",
                color: "#1F3B55",
                fontWeight: "bold",
                alignItems: "center",
              }}
            >
              Order Summary
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "-5px",
              }}
            >
              <hr />
            </div>
            <div className="flexContainer" style={{ display: "inline" }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: " -0.03em",
                  color: "#1468BA",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                Sub Total
              </div>
              <div
                style={{
                  marginRight: "10px",
                  float: "right",
                  fontSize: "16px",
                  color: "#2A2A2A",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                ${price.subtotal}
              </div>
            </div>
            <div className="flexContainer" style={{ paddingTop: "7px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: " -0.03em",
                  color: "#1468BA",
                  fontWeight: "600",
                  display: "inline-block",
                  fontFamily: "Poppins",
                }}
              >
                Inspection Discount
              </div>
              <div
                style={{
                  marginRight: "10px",
                  color: "black",
                  float: "right",
                  display: "inline-block",
                }}
              >
                {price.inspectionDiscount === null ? (
                  <div
                    style={{
                      color: "gray",
                      display: "inline-block",
                      fontWeight: 400,
                    }}
                  >
                    -
                  </div>
                ) : (
                  <b style={{ color: "red" }}>-${price.inspectionDiscount}</b>
                )}
              </div>
            </div>
            <div className="flexContainer" style={{ paddingTop: "7px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  letterSpacing: " -0.03em",
                  color: "#1468BA",
                  fontWeight: "600",
                  display: "inline-block",
                  fontFamily: "Poppins",
                }}
              >
                Promo Code :
              </div>

              <div
                style={{
                  marginRight: "10px",
                  float: "right",
                  fontSize: "17px",
                  fontWeight: 600,
                  fontStyle: "normal",
                  display: "inline-block",
                  fontFamily: "Poppins",
                }}
              >
                {price.promoCode === null ? (
                  <div
                    style={{
                      color: "black",
                      display: "inline-block",
                      fontWeight: 400,
                    }}
                  >
                    -
                  </div>
                ) : (
                  <div style={{ color: "#FF0000" }}>-${price.promoCode}</div>
                )}
              </div>
            </div>
            {/* style={{ color: "#FF0000" }} */}
            {/* <div className='flexContainer' style={{paddingTop:"7px"}}>
                                        <div style={{marginLeft: "10px",fontSize: "14px", letterSpacing:" -0.03em", color: "#1468BA", fontWeight: "600", display:"inline-block", fontFamily: 'Poppins' }}>
                                            Referral Code :
                                        </div>

                                        <div style={{marginRight: "10px",float:"right", fontSize:"17px", fontWeight: 600, fontStyle:"normal", display:"inline-block", fontFamily: 'Poppins' }}>
                                            {price.referral === null ?
                                                (
                                                    <div style={{color: 'gray',display: 'inline-block', fontWeight: 400}}>
                                                        -
                                                    </div>
                                                ):
                                                (
                                                    <div style={{color:"#FF0000"}}>
                                                        -${price.referral}
                                                    </div>

                                                )}

                                        </div>
                                    </div> */}

            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              <hr />
            </div>
            <div style={{ paddingBottom: "10px" }} className="flexContainer">
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: "-0.03em",
                  color: "#1F3B55",
                  display: "inline-block",
                  fontFamily: "Poppins",
                }}
              >
                Total:
              </div>
              <div
                style={{
                  marginTop: "-5px",
                  marginRight: "10px",
                  float: "right",
                  fontSize: "22px",
                  fontWeight: 600,
                  color: "#FF7A00",
                  display: "inline-block",
                  fontFamily: "Poppins",
                }}
              >
                ${price.total}
              </div>
            </div>
          </Card>

          {price?.multiCompanies?.length === 1 &&
          price?.multiCompanies[0]?.toLowerCase() == "zum" ? (
            // ||
            // (price?.multiCompanies?.length === 2 &&
            //     ((price?.multiCompanies[0]?.toLowerCase() == 'zum') ||
            //         (price?.multiCompanies[1]?.toLowerCase() == 'zum')
            //     ) )
            <div className="main-buttons-wrapper">
              {paymentStatusCheckout === true ? (
                <button
                  style={{ marginTop: 30 }}
                  disabled={vehicleStatusLoading}
                  className="main-buttons-style"
                  onClick={changeVehicleStatus}
                >
                  {vehicleStatusLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    "Payment"
                  )}
                </button>
              ) : (
                // <Button
                //     loading={loading}
                //
                //     title={'Payment True'}
                //     disabled={vehicleStatusLoading}
                //     onClickButton={changeVehicleStatus}
                // />
                <button
                  style={{ marginTop: 30 }}
                  disabled={vehicleStatusLoading}
                  className="main-buttons-style"
                  onClick={() => handleSkipPayment(paymentStatusCheckout)}
                >
                  {vehicleStatusLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    "Payment"
                  )}
                </button>
                // <Button
                //     loading={loading}
                //     style={{ marginTop: 30 }}
                //     title={'Payment False'}
                //     disabled={vehicleStatusLoading}
                //     onClick={()=>handleSkipPayment(paymentStatusCheckout)}
                // />
              )}
            </div>
          ) : (
            // (
            //   <Button loading={loading} style={{ marginTop: 30 }} title={'Payment'} disabled={buttonDisable} onClickButton={()=>handleSkipPayment(paymentStatusCheckout)} />
            // ):
            <>
              {paymentStatusCheckout === true ? (
                <>
                  <button
                    style={{ marginTop: 30 }}
                    disabled={vehicleStatusLoading}
                    className="main-buttons-style"
                    onClick={changeVehicleStatus}
                  >
                    {vehicleStatusLoading ? (
                      <ClipLoader color={"white"} size={20} />
                    ) : (
                      "Payment"
                    )}
                  </button>
                  {/*<div className="vec-inspection-submitbtn_container">*/}
                  {/*    <button*/}
                  {/*        disabled={vehicleStatusLoading}*/}
                  {/*        onClick={changeVehicleStatus}*/}
                  {/*        className="vec-inspection-submit_button"*/}
                  {/*    >*/}
                  {/*        {vehicleStatusLoading ? <ClipLoader color={'white'} size={20} /> : 'Submit'}*/}
                  {/*    </button>*/}
                  {/*</div>*/}
                </>
              ) : (
                <div className="main-buttons-wrapper">
                  <button
                    style={{ marginTop: 30 }}
                    className="main-buttons-style"
                    onClick={
                      mysterious
                        ? handleMysteroiusPayments
                        : handlePaymentTransaction
                    }
                  >
                    {vehicleStatusLoading ? (
                      <ClipLoader color={"white"} size={20} />
                    ) : (
                      "Payment"
                    )}
                  </button>
                </div>
              )}

              {/*<Button loading={loading} style={{ marginTop: 30 }} title={'Payment other'} disabled={buttonDisable} onClickButton={handleSubmit} />*/}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutScreen;
