import React from "react";
import "./style.css";

import { CustomNavbar } from "../../Components";
import { CustomFooter } from "../../Components";
import { Button, Input, Checkbox } from "antd";
import InputMask from "react-input-mask";
import ClipLoader from "react-spinners/ClipLoader";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";

const ReqDemoScreen = ({
  handleSubmit,
  //   handleReDirect,
  handleCheckBox,
  handleLoginRedirect,
  handleTermsOfUse,
  isLoading,
  breakPoints,
  form,
  Form
}) => {
  return (
    <div>
      <div className="landing_banner_main_wrapper_main">
        <div className="landing_banner_inner_wrapper2">
          <CustomNavbar scrollValue={30} />
          <div className="main-container-box">
            <div>
              <h1 className="heading-text">Request a demo</h1>
            </div>
            <div className="grid-wrapper">
              <div className="container-left">
                <p className="container-text">
                  See how your business can optimize vehicle inspection process.
                </p>

                <div className="chex-demo-video-main-contaiera">
                  <div className="chex-demo-video-containera">
                    <video
                      poster="https://res.cloudinary.com/techlingcompany/image/upload/v1661527657/Chex%20Images/banner_image_video_c1grug.png"
                      type="video/mp4"
                      playsInline
                      controls={true}
                      className="chex-demo-videoa"
                    >
                      <source
                        src="https://res.cloudinary.com/dgjordf6e/video/upload/v1695888050/CHex_ai_je0una.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <p className="container-text-2">
                  Chex.AI is an all-in-one solution customized for vehicle
                  inspections. Our technology removes the need for scheduled
                  appointments and expensive reviews. Inspections are completed
                  same day by a push of a button!
                </p>
              </div>
              <div className="container">
                <div className="right-container-box">
                  <div className="rigth-container-wrapper">
                    <div className="form_section_content_wrapper2">
                      <h5 className="right-container-text">
                        <b>
                          Please provide a few details to help us understand how
                          we can best serve your needs.
                        </b>
                      </h5>

                      <div className="form_wrapper_main">
                        <div className="">
                          <Form
                            form={form}
                            className="form"
                            autoComplete="off"
                            // {...layout}
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={handleSubmit}
                          >
                            <div className="namingSections_chex">
                              <Form.Item
                                name="firstName"
                                className="form-item-style-chex_first_ride"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your first name!",
                                  },
                                ]}
                              >
                                <Input
                                  className="input_field_chex_ride"
                                  placeholder="First Name"
                                  autoComplete="off"
                                />
                              </Form.Item>

                              <Form.Item
                                name="lastName"
                                className="form-item-style-chex_first_ride"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your last name!",
                                  },
                                ]}
                              >
                                <Input
                                  className="input_field_chex_ride"
                                  placeholder="Last Name"
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </div>
                            {/*----------------------------------------------------------- */}
                            <Form.Item
                              name="companyName"
                              className="form-item-style-chex"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your company name",
                                },
                              ]}
                            >
                              <Input
                                className="input_field_chex_ride"
                                placeholder="Company Name"
                                autoComplete="off"
                              />
                            </Form.Item>
                            <Form.Item
                              name="jobTitle"
                              className="form-item-style-chex"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your job title!",
                                },
                              ]}
                            >
                              <Input
                                className="input_field_chex_ride"
                                placeholder="Job Title"
                                autoComplete="off"
                              />
                            </Form.Item>
                            <Form.Item
                              name="email"
                              type="email"
                              className="form-item-style-chex"
                              rules={[
                                {
                                  // type: 'email',
                                  required: true,
                                  message: "Please enter valid email!",
                                  pattern: new RegExp(
                                    /^\s*(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/
                                  ),
                                },
                              ]}
                            >
                              <Input
                                className="input_field_chex_ride"
                                placeholder="Email"
                                autoComplete="off"
                              />
                            </Form.Item>

                            <Form.Item
                              name="phone"
                              className="form-item-style"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter phone number!",
                                },
                              ]}
                            >
                              <InputMask
                                mask="+1(999)999 9999"
                                className="form-control"
                                id="phone"
                                placeholder="Phone Number"
                                type="text"
                                name="phone"
                                autoComplete="phone"
                                required
                                disabled={false}
                              >
                                {() => (
                                  <Input
                                    id="phone"
                                    placeholder="Phone Number"
                                    type="text"
                                    name="phone"
                                    className="input_field_chex_ride"
                                    autoComplete="off"
                                  />
                                )}
                              </InputMask>
                            </Form.Item>

                            {/* <div style={{ marginBottom: 15, marginTop: 10 }}>
                              <div style={{ display: "flex" }}>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <label className="newcontainer">
                                      <input
                                        onChange={handleCheckBox}
                                        type="checkbox"
                                      />
                                      <span className="newcheckmark_chex_ride"></span>
                                    </label>
                                  
                                    <div className="register-checkbox-text_chex_ride">
                                      Yes, please keep me updated on news and
                                      special offers.
                                      <br />
                                      By submitting the form, you are agreeing
                                      to our privacy policy
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </div> */}

                            <Form.Item
                              name="specialOffer"
                              valuePropName="checked"
                              
                            >
                              <Checkbox  >
                                Yes, please keep me updated on news and special
                                offers.
                              </Checkbox>
                            </Form.Item>
                            <div className="register-checkbox-text_chex_ride_req">
                                      By submitting the form, you are agreeing
                                      to our privacy policy
                                    </div>
                            <Form.Item>
                              <Button
                                disabled={isLoading ? true : false}
                                className="button-wrapper_chex_ride"
                                htmlType="submit"
                              >
                                {isLoading ? (
                                  <ClipLoader color={"orange"} size={20} />
                                ) : (
                                  "Get Started"
                                )}
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="techStar-box">
              <div
                className="techStar_LogoCompany"
                style={{ textAlign: "center" }}
              >
                <p className="text_techStart_para_landing">
                  <span className="a_text_landing"> A </span>
                  <span>
                    <img
                      src="https://res.cloudinary.com/dgjordf6e/image/upload/v1665470856/texttechstart_xurufl_e4w9rg.png"
                      alt="techstar"
                      className="img_techStar2"
                    />
                  </span>{" "}
                  backed company
                </p>
              </div>
            </div>
      <div>
        <CustomFooter />
      </div>
    </div>
  );
};
export default ReqDemoScreen;
