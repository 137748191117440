import React from 'react';
import './style.css';
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import {
    AboutChexComponent,
    ChexSolutionComponent,
    CommunityBannerComponent, CustomFooter, CustomReviewsComponent, HelpBusinessSectionComponent,
    HomeBadgeBoxComponent,
    KeyBenefitsComponent, WhyChexAiComponent
} from "../../Components";
import {Col, Row} from "antd";
import Carousel from 'react-elastic-carousel';


const ChexLandingScreen = ({changeHanlder, about_breakPoints, carouselRef, breakPoints}) => {
    return(
        <div className='landing_screen_main_wrapper'>
            <LandingBannerComponent
                HomeLandingText={<div> <h3> <span className='darkGray'>Safety </span> Inspection, Compliance  <span className='darkGray'> And </span> maintenance <span className='darkGray'> Platform For The </span> <span className='darkBlue'> Mobility Industry </span></h3></div>}
                BannerImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/Group_495_whfm6k_vdrqjg.png'}
                redirectionPage={'/register'}
            />

            <div className='box_badge_wrapper_outer'>
                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_3_ktpims_ynuhl9.png"}
                    badgeHeading={"Compliance"}
                    badgeInfo={"Clear process to meet local, state and federal regulatory requirements"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_4_jp8igk_ezxbdv.png"}
                    badgeHeading={"Automation"}
                    badgeInfo={"Automated solution to onboard contractors, generate customized certification"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Vector_5_bccfay_lefazy.png"}
                    badgeHeading={"Visibility"}
                    badgeInfo={"Sufficient visibility around asset lifetime, safety and compliance"}

                />

            </div>



            <div>
                <ChexSolutionComponent
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470839/Group_473_zwl99h_iqf8z5.png'}

                />
            </div>


            <div>
                <WhyChexAiComponent
                    firstIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/Group_nbdukc_r0lawz.png'}
                    firstPara={'Partner dashboard for real time inspection access and compliance record keeping'}
                    secondIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470829/Group_1_xsrbyw_nopmcu.png'}
                    secondPara={'Onboard contractors fast through the use of Chex.AI’s inspection app'}
                    thirdIllustration={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470830/Frame_1_p9tvxr_d3oqyb.png'}
                    thirdPara={'Our partners get visibility into the asset health and predictability into asset life & overall safety'}

                />
            </div>




            <div className='key_benefits_wrapper_landing'>
                <KeyBenefitsComponent />

            </div>




            {/*<div className='main_wrapper_tailored_section_outer'>*/}
            {/*    <h2 className='heading_tailored_main'>*/}
            {/*        Our <span>platform</span> is tailored for*/}
            {/*    </h2>*/}
            {/*    <div className='main_wrapper_tailored_section_inner'>*/}
            {/*        <div className='solution_wrapper_boxes_outer '>*/}
            {/*            <div className='solution_wrapper_box line_design line_design_new bottom_line'>*/}
            {/*                <div className='image_wrapper_tailored_box'>*/}
            {/*                    <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/Group_488_zgt5zi.png'} alt=""/>*/}

            {/*                </div>*/}

            {/*                <h3>Rideshare/P2P</h3>*/}
            {/*                <p>*/}
            {/*                    Compliance Inspection Certification*/}
            {/*                </p>*/}

            {/*            </div>*/}

            {/*            <div className='solution_wrapper_box line_design bottom_line'>*/}
            {/*                <div className='image_wrapper_tailored_box'>*/}
            {/*                <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/Group_482_cwhljl.png'} className='car_image_mobility' alt=""/>*/}
            {/*                </div>*/}
            {/*                <h3>Micromobility</h3>*/}
            {/*                <p>*/}
            {/*                    Municipality Parking Compliance Solution*/}
            {/*                </p>*/}

            {/*            </div>*/}


            {/*            <div className='solution_wrapper_box line_design_new bottom_line'>*/}
            {/*                <div className='image_wrapper_tailored_box'>*/}
            {/*                <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/toppng_2_lapphl.png'} alt=""/>*/}
            {/*            </div>*/}
            {/*                <h3>Supply chain</h3>*/}
            {/*                <p>*/}
            {/*                    Asset Health and DVIR*/}
            {/*                </p>*/}

            {/*            </div>*/}


            {/*            <div className='solution_wrapper_box line_design bottom_line'>*/}
            {/*                <div className='image_wrapper_tailored_box'>*/}
            {/*                    <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/rental-motorhome-category-family_2_i1zma2.png'}  alt=""/>*/}
            {/*                </div>*/}
            {/*                <h3>Vehicle Purchase & Returns</h3>*/}
            {/*                <p>*/}
            {/*                    Pre Purchase and End of Lease Inspections*/}
            {/*                </p>*/}

            {/*            </div>*/}

            {/*            <div className='solution_wrapper_box line_design line_design_new bottom_line'>*/}
            {/*                <div className='image_wrapper_tailored_box'>*/}
            {/*                    <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/trucks_2_yl6zhp.png'} alt=""/>*/}
            {/*                </div>*/}
            {/*                <h3>Fleets/Rentals</h3>*/}
            {/*                <p>*/}
            {/*                    Damage/Wear and Asset Safety Analytics*/}
            {/*                </p>*/}

            {/*            </div>*/}


            {/*            <div className='solution_wrapper_box'>*/}
            {/*                <div className='image_wrapper_tailored_box '>*/}
            {/*                    <img src={'https://res.cloudinary.com/techlingcompany/image/upload/v1662619889/Frame_5_zjzrs6.png'} alt=""/>*/}
            {/*                </div>*/}
            {/*                <h3>Service Center Automation</h3>*/}
            {/*                <p>*/}
            {/*                    Paperless Checklist and Data Management*/}
            {/*                </p>*/}

            {/*            </div>*/}


            {/*        </div>*/}


            {/*        /!*<div className='solution_wrapper_boxes_outer'>*!/*/}
            {/*        /!*   *!/*/}

            {/*        /!*</div>*!/*/}


            {/*    </div>*/}


            {/*</div>*/}



            <section className='main_wrapper_tailored_section_outer'>
                <Row>
                    <Col>
                        <h2 className='heading_tailored_main'>
                            Our <span>platform</span> is tailored for
                        </h2>

                    </Col>
                </Row>
                <div className='main_wrapper_tailored_section_inner'>
                    <Row>
                        <Col xl={8} md={8} sm={24} className='padding_style line_design line_design_new bottom_line'>
                            <div className='solution_wrapper_box'>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470838/Group_488_zgt5zi_sjhzy4.png'} alt=""/>

                                </div>

                                <h3>Rideshare/P2P</h3>
                                <p>
                                    Compliance Inspection Certification
                                </p>

                            </div>

                        </Col>
                        <Col xl={8} md={8} sm={24} className='padding_style line_design line_design_new bottom_line'>
                            <div className='solution_wrapper_box'>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470836/Group_482_cwhljl_rdg3ez.png'} className='car_image_mobility' alt=""/>

                                </div>

                                <h3>Micromobility</h3>
                                <p>
                                    Municipality Parking Compliance Solution
                                </p>

                            </div>

                        </Col>
                        <Col xl={8} md={8} sm={24} xs={24} className='padding_style line_design_new bottom_line'>
                            <div className='solution_wrapper_box'>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470881/toppng_2_lapphl_nb8q0k.png'} alt=""/>

                                </div>

                                <h3>Supply chain</h3>
                                <p>
                                    Asset Health and DVIR
                                </p>

                            </div>

                        </Col>

                        <Col xl={8} md={8} sm={24} className='padding_style line_design line_design_new bottom_line'>
                            <div className='solution_wrapper_box '>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470854/rental-motorhome-category-family_2_i1zma2_gjkixr.png'} alt=""/>

                                </div>

                                <h3>Vehicle Purchase & Returns</h3>
                                <p>
                                    Pre Purchase and End of Lease Inspections
                                </p>

                            </div>

                        </Col>
                        <Col xl={8} md={8} sm={24} className='padding_style line_design line_design_new bottom_line'>
                            <div className='solution_wrapper_box '>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470859/trucks_2_yl6zhp_tmwbjl.png'} alt=""/>

                                </div>

                                <h3>Fleets/Rentals</h3>
                                <p>
                                    Damage/Wear and Asset Safety Analytics
                                </p>

                            </div>

                        </Col>
                        <Col xl={8} md={8} sm={24} className='padding_style line_design_new'>
                            <div className='solution_wrapper_box  '>
                                <div className='image_wrapper_tailored_box'>
                                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470828/Frame_5_zjzrs6_oe0cyc.png'} alt=""/>

                                </div>

                                <h3>Service Center Automation</h3>
                                <p>
                                    Paperless Checklist and Data Management
                                </p>

                            </div>

                        </Col>
                    </Row>
                </div>




            </section>


            <div>
                <AboutChexComponent />
            </div>
            <div className='community_wrapper_Landing'>
                <CommunityBannerComponent />
            </div>


            <section className='new_about_section_main'>


                <Row>
                    <Col xl={24} md={24} sm={24}>
                        <center>
                            <div className='techStar_LogoCompany'>
                                <p className='text_techStart_para_landing'>
                                    <span className='a_text_landing'> A </span><span><img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470856/texttechstart_xurufl_e4w9rg.png' alt='techstar' className='img_techStar' /></span> backed company
                                </p>

                            </div>

                            <div className='companylogo_container'>
                                <Carousel breakPoints={about_breakPoints} pagination={false} showArrows={true}
                                          ref={carouselRef}
                                          enableAutoPlay={true}
                                          onChange={changeHanlder}
                                          autoTabIndexVisibleItems={true}


                                >
                                    <div className="item_company">
                                        <img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470852/oxo_adfr21_insm1e.png' alt='company logo' />

                                    </div>
                                    <div className='item_company'>
                                        <img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/Zum_1_wyvc27_wk3j0d.png' alt='company logo' />

                                    </div>

                                    <div className='item_company'>
                                        <img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470816/adroid_logo_nu3wlq_effjfs.png' alt='company logo' />

                                    </div>

                                    <div className='item_company '>
                                        <img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470852/image_32_mhoawc_mwjnro.png' alt='company logo' />

                                    </div>

                                    <div className=''>
                                        {/* <img src='https://res.cloudinary.com/dgjordf6e/image/upload/v1665470859/uber_qy3kzf_ovhiq2.png' alt='company logo' /> */}

                                    </div>


                                </Carousel>
                            </div>




                        </center>

                    </Col>
                </Row>


            </section>


            <div>
                <HelpBusinessSectionComponent
                    headingSolution={<h2>How can we help your<span className='business_help_span_style'> business?</span></h2>}
                    paraSolution={'We can help your business optimize asset inspection process. Our interactive dashboard allows you to review data in real time and provide feedback to your customers instantaneously!'}
                    buttonText={'Contact Us'}
                    wrapper_background_style={'wrapper_background_style'}
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470847/Group_448_nkwgnj_uiddnk.png'}

                />

            </div>


            <div>
                <CustomReviewsComponent breakPoints={breakPoints}  />
            </div>

            <CustomFooter />




        </div>
    )
}
export default ChexLandingScreen