/* eslint-disable */

import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActionCreators from "./actions";

import RestrictedRoute from "./utils/routes/restrictedRoutes";
import PublicRoute from "./utils/routes/publicRoutes";
// import logo from './logo.svg';
import {
  IntroContainer,
  LoginContainer,
  SignUpContainer,
  ForgotPasswordContainer,
  ResetPasswordContainer,
  VerifyEmailContainer,
  ConfirmEmailContainer,
  VehicleInspectionContainer,
  CheckOutContainer,
  TransactionContainer,
  VehicleStatusContainer,
  ThankyouContainer,
  VehicleDetailContainer,
  DoAndDoesContainer,
  TermsAndPolicyContainer,
  ContactUsContainer,
  VehicleCombinedStatusContainer,
  VehicleAfterReviewingContainer,
  InspectionInstructionContainer,
  SelectCompanyContainer,
  SelectOptionContainer,
  InspectionInProgressContainer,
  InspectionSubmittedContainer,
  VehicleCertificateContainer,
  UnSubscribeContainer,
  HomeLandingContainer,
  LyftInspectionContainer,
  UberInspectionContainer,
  FaqsContainer,
  RideSharePageContainer,
  MicromobilityContainer,
  FleetManagerContainer,
  RefralContainer,
  BlogContainer,
  BlogDetailContainer,
  ReqADemoPageContainer,
  ConfirmVehicleDetailsContainer,
} from "./Containers";

import {
  LogoutForChanges,
  MaintancePage,
  NotFound,
  PromoCodeScreen,
  ThankyouReupload,
} from "../src/Screens";

import "./App.css";
import NewContactContainer from "./Containers/NewContactContainer";
import validatePromoTokenContainer from "./Containers/ValidatePromoTokenContainer";
import ScooterUploadContainer from "./Containers/ScooterUploadContainer";
import { ContactUsLogoComponent } from "./Components";

function App(props) {
  const doAndDont = localStorage.getItem("doAndDont");
  if (doAndDont == false) {
    localStorage.setItem("doAndDont", false);
  }

  const { currentUser } = props;
  let isAuthenticated = currentUser ? true : false;
  
  const location = useLocation();
  return (
    <>
      {location.pathname != "/contactUs" ? <ContactUsLogoComponent /> : null}

      <Switch>
        {/* <Route path="/" component={MaintancePage} /> */}
        <Route path="/getStarted" component={IntroContainer} />
        <Route path="/contactPage" component={NewContactContainer} />
        <Route path="/blogPage" component={BlogContainer} />
        <Route path="/uber-inspection" component={UberInspectionContainer} />
        <Route path="/lyft-inspection" component={LyftInspectionContainer} />
        <Route path="/ride-share" component={RideSharePageContainer} />
        <Route path="/micro-mobility" component={MicromobilityContainer} />
        <Route path="/fleet-manager" component={FleetManagerContainer} />
        <Route path="/faqs" component={FaqsContainer} />
        <Route path={`/reupload`} component={ScooterUploadContainer} />
        <Route path={`/thankyouScreenUpload`} component={ThankyouReupload} />
        <Route path={`/blog-detail/:id`} component={BlogDetailContainer} />
        <Route path={`/ReqADemo`} component={ReqADemoPageContainer} />

        <Route path={`/ReqADemo`} component={ReqADemoPageContainer} />

        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/confirmDetail/:inspectionId/:vehicleId"
          component={ConfirmVehicleDetailsContainer}
        />

        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/selectCompany/:inspection"
          component={SelectCompanyContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/inspectionInstruction"
          component={InspectionInstructionContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/selectOption"
          component={SelectOptionContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/inspectioninprogress"
          component={InspectionInProgressContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/inspectionsubmitted"
          component={InspectionSubmittedContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/vehiclecertificate"
          component={VehicleCertificateContainer}
        />
        {/* <RestrictedRoute
            storeAuthenticate={isAuthenticated}
            allowed={isAuthenticated}
            path="/referral"
            component={RefralContainer}
        /> */}
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/UnSubscribe"
          component={UnSubscribeContainer}
        />
        {/* <RestrictedRoute
        storeAuthenticate={isAuthenticated}
        allowed={isAuthenticated}
        path="/checkoutScreen/:id/:vehicleId"
        component={CheckOutContainer}
      /> */}
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/checkoutScreen/:id/:vehicleId"
          component={CheckOutContainer}
          key={location.key}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/transactionScreen/:id/:vehicleId"
          component={TransactionContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/userVehicleStatus"
          component={VehicleCombinedStatusContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/vehicleinspection/:id/:vehicleId"
          component={VehicleInspectionContainer}
        />
        <Route
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/logoutForChanges"
          component={LogoutForChanges}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/vehicleStatus"
          component={VehicleStatusContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/VehicleAfterReviewing/:id/:vehicleId"
          component={VehicleAfterReviewingContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/contactUs"
          component={ContactUsContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/doAndDoesScreen"
          component={DoAndDoesContainer}
        />
        <RestrictedRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/vehicleDetail"
          component={VehicleDetailContainer}
        />
        {/* <Route path="/termsAndPolicy" component={TermsAndPolicyContainer} /> */}
        {/* <Route path="/thankyouScreen" component={ThankyouContainer} /> */}
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/termsAndPolicy"
          component={TermsAndPolicyContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/thankyouScreen"
          component={ThankyouContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/pageNotFound"
          component={NotFound}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/register"
          component={SignUpContainer}
        />
        <Route path="/confirmEmail" component={ConfirmEmailContainer} />
        <Route path="/forgotpassword" component={ForgotPasswordContainer} />
        {/*<Route path="/promoCodeScreen" component={PromoCodeScreen}/>*/}
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/login"
          component={LoginContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/resetpassword"
          component={ResetPasswordContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/verifyEmail"
          component={VerifyEmailContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/partner"
          component={validatePromoTokenContainer}
        />
        <PublicRoute
          storeAuthenticate={isAuthenticated}
          allowed={isAuthenticated}
          path="/"
          component={HomeLandingContainer}
        />
        {/* <RestrictedRoute exact storeAuthenticate={isAuthenticated} allowed={isAuthenticated} path="/logoutForChanges" component={LogoutForChanges} /> */}
        {/* <Route path="/transactionScreen" component={TransactionContainer} /> */}
      </Switch>
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    vehicleData: state.auth.vehicleData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
