import React from "react";
import "./style.css";

const PricingCardRide = ({
  deboseImage,
  priceValue,
  priceValueDiscount,
  inspectionDetail,
  textDebose,
  imageLyft,
  imageDouble,
}) => {
  return (
    <div className="pricing_card_main_ride">
      <div className="card_debose_section_wrap">
        <div className="image_debose_part_wrapper">
          <img
            src={deboseImage}
            className={`${
              imageLyft ? "imageLyft" : imageDouble ? "imageDouble" : null
            }`}
            alt=""
          />

          <p className="para_debose_point">{textDebose}</p>
        </div>
      </div>

      <div className="content_section_pricing_card_wrapper">
        <h3 className="heading_content_pricing">
          <span className="discount">
            <del className="amount">${priceValue}</del>
          </span>
          <div>${priceValueDiscount}</div>
        </h3>
        <p className="para_pricing_content">{inspectionDetail}</p>
      </div>
    </div>
  );
};

export default PricingCardRide;
